import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { Xulaservice } from './service/Xulaservice';

export class AppBreadcrumb extends Component {

    constructor() {
        super();

        this.state = {
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            message: '',
            connection: 'O',
            color: '#FC1805',
        }
        this.xulaservice = new Xulaservice();
    }

    static propTypes = {
        match: PropTypes.object
    }


    componentDidMount() {

        let msg = localStorage.getItem('message');
        if (msg === undefined || msg === 'undefined') {
            msg = '';
        }
        this.get_connection();
        /* this.setState({ message: msg });
        
        
        this.timer = setInterval(() => this.get_last_msg(), 1800000);
        
        this.timer2 = setInterval(() => this.get_connection(), 7000);
 */


    }

    get_connection() {
        let color = '#FC1805';
        try { 
            if (window.navigator.onLine == true) {
                let bande = window.navigator.connection.downlink;
                let reseau = window.navigator.connection.effectiveType;
                this.setState({ connection: reseau + ' ' + bande })
                if (bande > 1.5) {
                    color = '#1EAC4B'
                } else if (bande > 0) {
                    color = '#DFD90E';
                } else {
                    color = '#FC1805';
                }
                this.setState({ color: color });
            } else {
                this.setState({ connection: 'Offline' });
                color = '#FC1805';
                this.setState({ color: color });
            }

        } catch (error) {
            this.setState({ connection: error });
            this.setState({ bande: 0 });
            color = '#FC1805';
            this.setState({ color: color });
        }
    }

    componentWillUnmount() {
        if (localStorage.getItem('offline') == 'false') {
            this.timer = null;
        }
        this.timer2 = null;
    }

    get_last_msg() {
        var lien = this.xulaservice.wsurl() +'/etats/get_last_msg?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
        })
            .then(response => {
                if (response.ok) {
                    return response.text();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                localStorage.setItem('message', data);
                this.setState({ message: data });
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }



    render() {
        const { location } = this.props;
        const paths = location.pathname.split('/');
        return (
            <div className="layout-breadcrumb">
                <ul>
                    <li><button className="p-link"><i className="fa fa-home"></i></button></li>
                    {
                        location.pathname === '/' ? <li>/</li> : paths.map((path, index) => <li key={index}>{path === '' ? '/' : path}</li>)
                    }
                </ul>
                <marquee style={{ color: 'red', fontWeight: 'bold', fontSize: 18,width: '60%',marginTop: 10 }}>{this.state.message}</marquee>
                <div className="layout-breadcrumb-options">

                    <Button label={this.state.connection} style={{ width: 80, height: 30, marginTop: -5, fontSize: 14, color: '#fff', fontWeight: 'bold', borderRadius: 50, backgroundColor: this.state.color }} className="p-button-success" />

                    <button className="p-link" title="Changer mot de passe" onClick={(e) => {
                        window.location = "#/changer_mot_de_pass";
                    }} >
                        <i className="fa fa-user"></i>
                    </button>
                    <button className="p-link" title="Logout" onClick={(e) => {
                        localStorage.removeItem('message');
                        localStorage.removeItem('userconnected');
                        localStorage.removeItem('offline', 'false');
                        localStorage.removeItem('srv_url', 'https://new.xulanet.com/ws_xula/xula');
                        //localStorage.setItem('srv_url', 'http://192.168.100.10:9077/ws_xula/xula');
                        window.location = "#/login";
                    }} >
                        <i className="fa fa-sign-out"></i>
                    </button>
                </div>
                <br/>
                

            </div>
        );
    }
}