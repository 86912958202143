import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { Panel } from 'primereact/components/panel/Panel';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Link } from 'react-router-dom';

export class Dashboard extends Component {

    constructor() {
        super();
        this.state = {
            total_dep: 0,
            total_encaisse: 0,
            solde_jrn: 0,
            total_credit: 0,
            total_du: 0,
            total_gain: 0,
            total_dep_anne: 0,
            total_vente_annee: 0,
            total_om: 0,
            total_momo: 0,
            total_non_livre_btq: '0',
            total_non_livre_intermag: '0',

            stotal_dep: '0',
            stotal_du_client: '0',
            stotal_encaisse: '0',
            ssolde_jrn: '0',
            ssolde_gnle: '0',
            stotal_credit: '0',
            color_soldegnle: 'white',
            color_rentabilite: 'black',
            disable_etabl: true,
            showindicator: false,

            visible_dlg_alerte: false,

            stotal_du: '0',
            stotal_gain: '0',

            stotal_rentabilite: '0',

            liste_article: [],
            liste_boutique: [],
            topNliste_article: [],
            liste_article_seuil: [],
            liste_non_regle: [],
            liste_peremption: [],
            liste_vente_mois: [],
            liste_situation_boutique: [],
            liste_st_dep: [],
            idBoutiq: { label: 'Toutes les boutiques', value: '-1' },
            liste_delai_pay_sortie: [],
            nbseuil_atteint: 0,
            valeur_marchand: 0,
            svaleur_marchand: '0',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),

            stotal_credit_global: '0',
            stotal_gain_annee: '0',
            stotal_depense_annee: '0',
            stotal_vente_annee: '0',
            stotal_rgl_vente_annee: '0',
            stotal_cmde_annee: '0',
            staux_recouvrement_annee: '0',
            stotal_non_livre: '0',

            stotal_du_interne: '0',
            stotal_du_four: '0',
            stotal_credit_interne: '0',
            rannee: moment(new Date()).format('YYYY'),
            visible_dlg_detail: false,
            liste_data_detail: [],
            fm_detail: '',

        };
        this.xulaservice = new Xulaservice();
        this.show_dlg_detail = this.show_dlg_detail.bind(this);
        if (this.state.userconnected.profilUser == 'PDG' || this.state.userconnected.profilUser == 'Coordinateur') {
            this.setState({ disable_etabl: false });
        }
    }

    show_dlg_detail(fm_detail) {
        this.setState({ visible_dlg_detail: true });
        this.setState({ fm_detail: fm_detail });
        if (fm_detail == 'TT_RCV') {
            this.get_detail_recouvrement();
        } else if (fm_detail == 'TT_DU_CLI') {
            this.get_detail_ttdu_client();
        } else if (fm_detail == 'TT_NL_BTQ') {
            this.get_detail_tt_nl_btq();
        } else if (fm_detail == 'TT_NL_INTERMAG') {
            this.get_detail_tt_nl_intermag();
        } else if (fm_detail == 'TT_NL_ALL') {
            this.get_detail_tt_nl_all();
        }
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_detail_tt_nl_btq() {
        this.setState({ showindicator: true });
        this.xulaservice.get_detail_tt_non_livre(this.state.idBoutiq, 'Tous', 'BTQ').then(data => this.setState({ liste_non_regle: data, showindicator: false }));
    }
    get_detail_tt_nl_intermag() {
        this.setState({ showindicator: true });
        this.xulaservice.get_detail_tt_non_livre(this.state.idBoutiq, 'Tous', 'INTERMAG').then(data => this.setState({ liste_non_regle: data, showindicator: false }));
    }
    get_detail_tt_nl_all() {
        this.setState({ showindicator: true });
        this.xulaservice.get_detail_tt_non_livre(this.state.idBoutiq, 'Tous', 'Tous').then(data => this.setState({ liste_non_regle: data, showindicator: false }));
    }
    get_detail_ttdu_client() {
        this.setState({ showindicator: true });
        this.xulaservice.get_detail_ttdu_client(this.state.idBoutiq, 'Tous').then(data => this.setState({ liste_data_detail: data, showindicator: false }));
    }
 
    get_detail_recouvrement() {
        this.setState({ showindicator: true });
        this.xulaservice.get_detail_recouvrement(this.state.idBoutiq, 'Tous').then(data => this.setState({ liste_data_detail: data, showindicator: false }));
    }

    compare(a, b) {
        const mtA = a.montant;
        const mtB = b.montant;
        let comparison = 0;
        if (mtA > mtB) {
            comparison = 1;
        } else if (mtA < mtB) {
            comparison = -1;
        }
        return comparison;
    }

    charger_dashboard(idBoutiq, annee, show) {
        this.setState({ stotal_credit: '0' });
        this.setState({ stotal_dep: '0' });
        this.setState({ ssolde_jrn: '0' });
        this.setState({ stotal_du: '0' });
        this.setState({ stotal_encaisse: '0' });
        this.setState({ stotal_gain: '0' });
        this.setState({ liste_article: [] });
        this.setState({ liste_article_seuil: [] });
        this.setState({ liste_delai_pay_sortie: [] });
        this.setState({ liste_vente_mois: [] });
        this.setState({ liste_peremption: [] });
        this.setState({ liste_st_dep: [] });
        this.setState({ liste_situation_boutique: [] });
        this.setState({ svaleur_marchand: '0' });
        this.setState({ stotal_credit_global: '0' });
        this.setState({ stotal_non_livre: '0' });
        this.setState({ staux_recouvrement_annee: '0' });
        this.setState({ stotal_cmde_annee: '0' });
        this.setState({ stotal_rgl_vente_annee: '0' });
        this.setState({ stotal_vente_annee: '0' });
        this.setState({ stotal_depense_annee: '0' });
        this.setState({ stotal_gain_annee: '0' });
        this.setState({ color_soldegnle: 'white' });
        this.setState({ stotal_du_interne: '0' });
        this.setState({ stotal_du_four: '0' });
        this.setState({ stotal_credit_interne: '0' });
        this.setState({ ssolde_gnle: '0' });
        this.setState({ stotal_du_client: '0' });
        this.setState({ stotal_rentabilite: '0' });
        this.setState({ color_rentabilite: 'black' });
        this.setState({ total_om: '0' });
        this.setState({ total_momo: '0' });
        this.setState({ total_non_livre_btq: '0' });
        this.setState({ total_non_livre_intermag: '0' });

        this.setState({ showindicator: true });
        this.xulaservice.get_dashboard(idBoutiq, annee).then(data => {
            if (data !== null && data !== undefined) {
                this.setState({ stotal_credit: data.stotal_credit });
                this.setState({ stotal_dep: data.stotal_dep });
                this.setState({ ssolde_jrn: data.ssolde_jrn });
                this.setState({ ssolde_gnle: data.ssolde_gnle });
                this.setState({ stotal_du: data.stotal_du });
                this.setState({ stotal_encaisse: data.stotal_encaisse });
                this.setState({ stotal_gain: data.stotal_gain });
                this.setState({ liste_article: data.liste_article });
                this.setState({ liste_article_seuil: data.liste_article_seuil });
                this.setState({ liste_delai_pay_sortie: data.liste_delai_pay_sortie });
                this.setState({ liste_vente_mois: data.liste_vente_mois });
                this.setState({ liste_peremption: data.liste_peremption });
                this.setState({ liste_st_dep: data.liste_st_dep });
                this.setState({ liste_situation_boutique: data.liste_situation_boutique });
                this.setState({ svaleur_marchand: this.formaterMontant(parseFloat(data.valeur_marchand)) });
                this.setState({ stotal_credit_global: this.formaterMontant(parseFloat(data.total_credit_global)) });
                this.setState({ stotal_non_livre: this.formaterMontant(parseFloat(data.total_non_livre)) });
                this.setState({ staux_recouvrement_annee: data.taux_recouvrement_annee });
                this.setState({ stotal_cmde_annee: this.formaterMontant(parseFloat(data.total_cmde_annee)) });
                this.setState({ stotal_rgl_vente_annee: this.formaterMontant(parseFloat(data.total_rgl_vente_annee)) });
                this.setState({ stotal_vente_annee: this.formaterMontant(parseFloat(data.total_vente_annee)) });
                this.setState({ stotal_depense_annee: this.formaterMontant(parseFloat(data.total_depense_annee)) });
                this.setState({ stotal_gain_annee: this.formaterMontant(parseFloat(data.total_gain_annee)) });
                this.setState({ stotal_du_client: this.formaterMontant(parseFloat(data.total_du_client)) });
                this.setState({ total_om: this.formaterMontant(parseFloat(data.total_om)) });
                this.setState({ total_momo: this.formaterMontant(parseFloat(data.total_momo)) });
                this.setState({ total_non_livre_btq: this.formaterMontant(parseFloat(data.total_non_livre_btq)) });
                this.setState({ total_non_livre_intermag: this.formaterMontant(parseFloat(data.total_non_livre_intermag)) });

                if (data.liste_situation_boutique != null && data.liste_situation_boutique != undefined) {
                    let initialValue = 0;
                    let tt = data.liste_situation_boutique.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.rentabiliteBoutiq
                    }, initialValue);
                    this.setState({ stotal_rentabilite: this.formaterMontant(tt) });
                    if (tt > 0) {
                        this.setState({ color_rentabilite: 'green' });
                    } else {
                        this.setState({ color_rentabilite: 'red' });
                    }
                }

                let visible = false;
                if (data.liste_peremption !== undefined) {
                    if (data.liste_peremption.length > 0) {
                        visible = true;
                    }
                }
                if (data.liste_delai_pay_sortie !== undefined) {
                    if (data.liste_delai_pay_sortie.length > 0) {
                        visible = true;
                    }
                }
                if (data.liste_article_seuil !== undefined) {
                    if (data.liste_article_seuil.length > 0) {
                        visible = true;
                    }
                }
                if (visible == true && show == true) {
                    this.setState({ visible_dlg_alerte: visible });
                }

                if (data.solde_gnle < 0) {
                    this.setState({ color_soldegnle: '#ff0303' });
                } else if (data.solde_gnle > 0) {
                    this.setState({ color_soldegnle: '#35ab13' });
                }
                this.setState({ stotal_du_interne: this.formaterMontant(parseFloat(data.total_du_interne)) });
                this.setState({ stotal_du_four: this.formaterMontant(parseFloat(data.total_du_four)) });
                this.setState({ stotal_credit_interne: this.formaterMontant(parseFloat(data.total_credit_interne)) });
            }
            this.setState({ showindicator: false });
        }
        );
    }

    componentDidMount() {
        let lboutique = [{ value: '-1', label: 'Toutes les boutiques' }];
        if (this.state.userconnected.profilUser == 'Directeur') {
            this.xulaservice.getListe_boutique_gerer().then(data => {
                this.setState({ liste_boutique: data }, () => {
                    if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                        let liste = this.state.liste_boutique.map(x => {
                            return { value: x.idBoutiq, label: x.nomBoutiq }
                        });
                        lboutique = lboutique.concat(liste);
                    }
                });
                this.setState({ lboutique: lboutique }, () => {
                    this.setState({ idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq }, () => {
                        this.charger_dashboard(this.state.idBoutiq, this.state.rannee, true);
                    });
                });
            });
        } else {
            this.xulaservice.getListe_boutique().then(data => {
                this.setState({ liste_boutique: data }, () => {
                    if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                        let liste = this.state.liste_boutique.map(x => {
                            return { value: x.idBoutiq, label: x.nomBoutiq }
                        });
                        lboutique = lboutique.concat(liste);
                    }
                });
                this.setState({ lboutique: lboutique }, () => {
                    this.setState({ idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq }, () => {
                        this.charger_dashboard(this.state.idBoutiq, this.state.rannee, true);
                    });
                });
            });
        }

    }




    render() {
        let liste_label_stat_vente = null;
        let liste_ds_stat_vente_a = null;
        let liste_ds_stat_vente_am1 = null;
        if (this.state.liste_vente_mois != null && this.state.liste_vente_mois != undefined) {
            if (this.state.liste_vente_mois.length > 0) {
                liste_label_stat_vente = this.state.liste_vente_mois.map(x => {
                    return x.lib
                });
                liste_ds_stat_vente_a = this.state.liste_vente_mois.map(x => {
                    return x.dval2
                });
                liste_ds_stat_vente_am1 = this.state.liste_vente_mois.map(x => {
                    return x.dval
                });
            }
        }

        const chart_data_stat_vente = {
            labels: liste_label_stat_vente,
            datasets: [
                {
                    label: 'Ventes année en cours',
                    backgroundColor: '#4071a7',
                    borderColor: '#4071a7',
                    fill: false,
                    data: liste_ds_stat_vente_a
                },
                {
                    label: 'Ventes année précédante',
                    backgroundColor: '#d87a3c',
                    borderColor: '#d87a3c',
                    fill: false,
                    data: liste_ds_stat_vente_am1
                }
            ]
        };

        let liste_label_top_prod = null;
        let liste_ds_top_prod = null;
        if (this.state.liste_article != null && this.state.liste_article != undefined) {
            if (this.state.liste_article.length > 0) {
                let liste = this.state.liste_article;
                liste.sort(this.compare);
                let items = liste.slice(0, 10);
                liste_label_top_prod = items.map(x => {
                    return x.libArt
                });

                liste_ds_top_prod = items.map(x => {
                    return x.montant
                });
            }
        }

        const chart_hdata_topProd = {
            labels: liste_label_top_prod,
            datasets: [
                {
                    label: 'Top 10 prdouits',
                    backgroundColor: '#4071a7',
                    data: liste_ds_top_prod
                }
            ]
        };


        return <div className="p-grid dashboard">
           
         
            <div className="p-col-12" style={{ marginTop: -10 }}>
                <div className="p-grid" style={{ float: "right", width: 350, marginBottom: -5, marginTop: -10 }}>
                    <div className="p-col">
                        <div className="p-inputgroup">
                            <Dropdown placeholder="Toutes les boutiques" style={{ width: 250, marginBottom: -8 }} value={this.state.idBoutiq} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.lboutique} onChange={(e) => {
                                this.setState({ idBoutiq: e.value }, () => {
                                    this.charger_dashboard(e.value, this.state.rannee, false);
                                });
                            }
                            } disabled={this.state.userconnected.profilUser != 'PDG' && this.state.userconnected.profilUser != 'Coordinateur' && this.state.userconnected.profilUser !== 'Directeur'} />
                            <Button type="button" style={{ marginBottom: -8, width: '30px' }} icon="pi pi-refresh" className="p-button-info" onClick={() => this.charger_dashboard(this.state.idBoutiq, this.state.rannee, true)} />
                            
                        </div>
                    </div>
                    <div className="p-col">
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </div>
                </div>

            </div>

            


            <div className="p-col-12 p-md-6">
                <Panel style={{ minHeight: '445px' }}>
                    <i className="fa fa-fw fa-shopping-cart"></i>Sorties du jour par article
                    <hr />
                    <DataTable value={this.state.liste_article} selectionMode="single" scrollable={true} scrollHeight="300px"
                    >
                        <Column field="libArt" filter={true} filterMatchMode='contains' sortable={true} header="Désignation" />
                        <Column field="qte" filter={true} filterMatchMode='contains' header="Qté" sortable={true} style={{ width: '18%' }} />
                    </DataTable>

                </Panel>
            </div>

            <div className="p-col-12 p-md-6">
                <Panel style={{ minHeight: '445px' }}>
                    <i className="fa fa-fw fa-bar-chart"></i>Top sortie article du jour
                    <hr />
                    <Chart type="horizontalBar" data={chart_hdata_topProd} height="160w" />
                </Panel>
            </div>

          
        </div>
    }
}