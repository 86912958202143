import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment, { utc } from 'moment';
import { SplitButton } from 'primereact/splitbutton';
import Cleave from 'cleave.js/react';
import { Panel } from 'primereact/panel';
import { InputTextarea } from 'primereact/inputtextarea';
import { OverlayPanel } from 'primereact/overlaypanel';
import Numeral from 'numeral';
import { FormSituation_client } from './FormSituation_client';


export class FormEdition_vente extends Component {
    constructor() {
        super();
        this.state = {
            total_facture: '0',
            total_regle: '0',
            total_restant: '0',
            total_gain: '0',
            modeRgl: { value: 'Espèce', label: 'Espèce' },
            refRgl: '',
            nb_row: 5,
            min_height: '820px',
            pd1: new Date(),
            pd2: new Date(),
            disable_situation_client: false,
            dateSortie: new Date(),
            ridBoutiq: null,
            selectedSortie: null,
            liste_sortie: [],
            liste_lsortie: [],
            liste_boutique: [],
            lboutique_rech: [],
            lclient_rech: [],
            lclient: [],
            lboutique: [],
            liste_generale_assure: [],
            liste_parent_assure: [],
            lparent_assure_rech: [],
            lassure_rech: [],
            liste_sortie_for_reglement: [],
            liste_sortie_for_reglement_selecteds: [],
            ridAssure: null,
            ridParent_assure: null,
            ridLivreur: null,
            ridClient: null,
            rtagSortie: null,
            rtypeClient: null,
            rnum_facture: '',
            rstatutLivraison: null,
            rstatutReglement: null,
            daterglSortie: new Date(),
            total_reglement_format: '0',
            mtrglSortie: 0,
            idCli_situation: null,
            modal: true,
            descOpcli: '',
            montantOpcli: 0,
            confmontantOpcli: 0,

            visible_dlg_reglement: false,
            visible_situation_client: false,
            visible_dlg_depot: false,
            showindicator: false,

            liste_statut_livraison: [
                { label: 'Livraison', value: 'Tous' },
                { label: 'Livré', value: 'LIVRE' },
                { label: 'Non livré', value: 'NON LIVRE' }
            ],

            liste_statut_reglement: [
                { label: 'Reglement', value: 'Tous' },
                { label: 'Reglé', value: 'Regle' },
                { label: 'Non reglé', value: 'Non regle' },
            ],
            liste_mode_reglement: [
                { label: 'Espèce', value: 'Espèce' },
                { label: 'Chèque', value: 'Chèque' },
                { label: 'Virement', value: 'Virement' },
                { label: 'Compte', value: 'Compte' },
                { label: 'Orange Money', value: 'Orange Money' },
                { label: 'Momo', value: 'Momo' },
                { label: 'UBA', value: 'UBA' },
                { label: 'EcoBank', value: 'EcoBank' },
                { label: 'Payement marchand', value: 'Payement marchand' },
            ],
            liste_type_cli: [
                { label: 'Type client', value: 'Tous' },
                { label: 'Particulier', value: 'Particulier' },
                { label: 'Default', value: 'Default' },
                { label: 'Client interne', value: 'Client interne' },
                { label: 'Assurance', value: 'Assurance' },
                { label: 'Organisation', value: 'Organisation' },
                { label: 'Administration publique', value: 'Administration publique' }
            ],
            items: [
                {
                    label: 'Factures en PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Factures en XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Exportation brute',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                },
                {
                    label: 'Détails en PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_detail('PDF');
                    }
                },
                {
                    label: 'Détails en XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_detail('XLS');
                    }
                },
                {
                    label: 'Compte client en PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_situation('PDF');
                    }
                }
            ],
            rfiltreClient: 'COMPTOIR',
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };

        this.conf_print = this.conf_print.bind(this);
        this.conf_print_detail = this.conf_print_detail.bind(this);
        this.conf_print_facture = this.conf_print_facture.bind(this);
        this.conf_print_situation = this.conf_print_situation.bind(this);
        this.myservice = new Xulaservice();
        this.show_situation_client_dlg = this.show_situation_client_dlg.bind(this);
        this.show_dlg_reglement = this.show_dlg_reglement.bind(this);
        this.show_dlg_depot = this.show_dlg_depot.bind(this);

    }

    conf_print_situation(format) {
        if (this.state.ridClient === null || this.state.ridClient === undefined || this.state.ridClient.value === 'Tous') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un client' });
            return;
        }
        this.myservice.generer_compte_client(this.state.ridClient.value, format);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.client != this.props.client) {
            if (this.props != undefined) {
                let client = this.props.client;
                if (client != null && client != undefined && client != '') {
                    this.setState({ ridClient: client }, () => {
                        this.get_liste_sortie();
                    });
                }
                this.setState({ nb_row: 10 });
                this.setState({ disable_situation_client: true });
                this.setState({ modal: false });
                this.setState({ min_height: '520px' });
            }
        }
    }




    enreg_depot() {

        if (this.state.montantOpcli === '' || this.state.montantOpcli === undefined || this.state.montantOpcli === 0 || this.state.ridClient === '' || this.state.ridClient === undefined || this.state.ridClient === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (this.state.montantOpcli != this.state.confmontantOpcli) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier le montant saisi ' });
        } else {
            let data = { idOpcli: '0', statutSyncOpcli: this.myservice.get_statut_sync(), typeOpcli: 'ENTREE', descOpcli: this.state.descOpcli, montantOpcli: this.state.montantOpcli, refOpcli: '', libOpcli: 'Dépot', idboutiqOpcli: { idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq }, idCli: { idCli: this.state.ridClient.value } };
            this.myservice.enreg_operation_client(data).then(data => {
                if (data.code == '200') {
                    this.growl.show({ severity: 'success', summary: 'Success', detail: data.contenu });
                    this.get_liste_sortie();
                    this.get_liste_client();
                    this.annule_depot();
                } else {
                    this.growl.show({ severity: 'error', summary: 'Error', detail: data.contenu });
                }
            });
        }
    }

    conf_print_detail(format) {
        let typesortie = 'Facture';
        let rstatut_reglement = 'Tous';
        let rid_client = 'Tous';
        let rid_parent_assure = 'Tous';
        let rid_assure = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.rstatutReglement != undefined || this.state.rstatutReglement != null) {
            rstatut_reglement = this.state.rstatutReglement.value;
        }
        if (this.state.ridClient != undefined || this.state.ridClient != null) {
            rid_client = this.state.ridClient.value;
        }
        if (this.state.ridParent_assure != undefined || this.state.ridParent_assure != null) {
            rid_parent_assure = this.state.rid_parent_assure.value;
        }
        if (this.state.ridAssure != undefined || this.state.ridAssure != null) {
            rid_assure = this.state.ridAssure.value;
        }

        this.myservice.generer_details_facture(spd1, spd2, rstatut_reglement, rid_client, rid_parent_assure, rid_assure, typesortie, format);
    }


    conf_print(format) {
        let typesortie = 'Facture';
        let rstatut_livraison = 'Tous';
        let rstatut_reglement = 'Tous';
        let rboutique = 'Tous';
        let rid_client = 'Tous';
        let rtype_client = 'Tous';
        let rnum_facture = 'Tous';
        let rlivreur = 'Tous';
        let rid_parent_assure = 'Tous';
        let rid_assure = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        let rtag = 'Tous';
        if (this.state.rtagSortie != undefined || this.state.rtagSortie != null) {
            rtag = this.state.rtagSortie.value;
        }
        if (this.state.ridBoutiq != undefined || this.state.ridBoutiq != null) {
            rboutique = this.state.ridBoutiq.value;
        }
        if (this.state.rstatutReglement != undefined || this.state.rstatutReglement != null) {
            rstatut_reglement = this.state.rstatutReglement.value;
        }
        if (this.state.rstatutLivraison != undefined || this.state.rstatutLivraison != null) {
            rstatut_livraison = this.state.rstatutLivraison.value;
        }
        if (this.state.ridClient != undefined || this.state.ridClient != null) {
            rid_client = this.state.ridClient.value;
        }
        if (this.state.ridParent_assure != undefined || this.state.ridParent_assure != null) {
            rid_parent_assure = this.state.ridParent_assure.value;
        }
        if (this.state.ridAssure != undefined || this.state.ridAssure != null) {
            rid_assure = this.state.ridAssure.value;
        }
        if (this.state.ridLivreur != undefined || this.state.ridLivreur != null) {
            rlivreur = this.state.ridLivreur.value;
        }
        if (this.state.rnum_facture != undefined || this.state.rnum_facture != null) {
            rnum_facture = this.state.rnum_facture.value;
        }
        if (this.state.rtypeClient != undefined || this.state.rtypeClient != null) {
            rtype_client = this.state.rtypeClient.value;
        }

        this.myservice.generer_edition_jrn_vente(spd1, spd2, rnum_facture, rboutique, rstatut_reglement, rstatut_livraison, rid_client, rid_parent_assure, rid_assure, rlivreur, typesortie, format, rtype_client, rtag);
    }

    conf_print_facture(data, format) {
        this.myservice.generer_edition_facture(data.idSortie, data.typeSortie, format);
    }



    show_dlg_reglement() {
        let liste = [];
        if (this.state.liste_sortie.length > 0) {
            liste = this.state.liste_sortie.filter(function (el) {
                return el.mt_restant > 0;
            })
        }
        this.setState({ liste_sortie_for_reglement: liste }, () => {
            this.setState({ visible_dlg_reglement: true });
        });
    }

    show_dlg_depot() {
        this.setState({ visible_dlg_depot: true });
    }


    show_situation_client_dlg() {
        if (this.state.idCli_situation != null && this.state.idCli_situation != undefined) {
            if (this.state.idCli_situation.value != 'Tous') {
                this.setState({ visible_situation_client: true });
            } else {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un client' });
            }
        } else {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un client' });
        }
    }

    montantReglBodyTemplate(rowData, column) {
        let val = this.formaterMontant(rowData.mtrglSortie);
        return <div>
            <span style={{ fontWeight: 'bold' }}>{val}</span><br />
        </div>
    }

    get_liste_sortie_for_reglement() {
        this.setState({ showindicator: true });
        let rid_client = 'Tous';
        let rid_parent_assure = 'Tous';
        let rid_assure = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.ridClient != undefined || this.state.ridClient != null) {
            rid_client = this.state.ridClient.value;
        }
        if (this.state.ridParent_assure != undefined || this.state.ridParent_assure != null) {
            rid_parent_assure = this.state.ridParent_assure.value;
        }
        if (this.state.ridAssure != undefined || this.state.ridAssure != null) {
            rid_assure = this.state.ridAssure.value;
        }
        this.setState({ liste_sortie_for_reglement_selecteds: [] });
        this.setState({ total_reglement_format: '0' });
        this.myservice.get_liste_sortie_by_criteria(spd1, spd2, 'Tous', 'Tous', 'Non regle', 'Tous', rid_client, rid_parent_assure, rid_assure, 'Tous', 'Facture', 'Tous').then(data => this.setState({ liste_sortie_for_reglement: data, showindicator: false }));
    }

    clientItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-9">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label>{option.telCli}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    componentDidMount() {
        this.get_liste_boutique();
        if (this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie') {
            this.get_liste_assure();
            this.get_liste_parent_assure();
            this.setState({ rfiltreClient: 'Tous' }, () => {
                this.get_liste_client();
            })
        } else {
            if (this.state.userconnected.idEtabl.codeEtabl !== 'EDK') {
                this.setState({ rfiltreClient: 'Tous' }, () => {
                    this.get_liste_client();
                });
            }
        }
    }

    get_liste_assure() {
        this.myservice.get_liste_assure().then(data => {
            if (data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.idAss, label: x.nomAss, matrAss: x.matrAss, idCli: x.idParentAss.idCli.idCli, nomCli: x.idParentAss.idCli.nomCli, label_rech: x.nomAss + ' ' + x.idAss, nomSouscripteur: x.idParentAss.nomParentAss, idParentAss: x.idParentAss.idParentAss }
                });
                this.setState({ liste_generale_assure: liste });
            }
        });
    }

    get_liste_parent_assure() {
        this.myservice.get_liste_parent_assure('Tous').then(data => {
            if (data !== null && data !== undefined) {
                this.setState({ liste_parent_assure: data }, () => {
                    let lparent_assure = this.state.liste_parent_assure.map(x => {
                        return { value: x.idParentAss, label: x.nomParentAss, idCli: x.idCli.idCli, nomCli: x.idCli.nomCli, label_rech: x.nomParentAss + ' ' + x.idParentAss }
                    });
                    this.setState({ liste_parent_assure: lparent_assure });
                });
            }
        });
    }

    select_parent_assure_by_client(idClient) {
        let liste_filtre = [{ value: 'Tous', label: 'Souscripteur', idCli: '0', nomCli: '', label_rech: 'Tous' }];
        if (this.state.liste_parent_assure != null && this.state.liste_parent_assure != undefined) {
            if (this.state.liste_parent_assure.length > 0) {
                let liste = this.state.liste_parent_assure.filter(function (el) {
                    return el.idCli == idClient;
                });
                liste_filtre = liste_filtre.concat(liste);
            }
        }
        this.setState({ lparent_assure_rech: liste_filtre });
    }

    select_assure_by_parent(idParentAss) {
        let liste_filtre = [{ value: 'Tous', label: 'Assuré', pchargeAss: 0, remiseAss: 0, matrAss: '', idCli: '-1', nomCli: '', remiseCli: 0, label_rech: 'Non applicable', nomSouscripteur: 'Non applicable' }];
        if (this.state.liste_generale_assure != null && this.state.liste_generale_assure != undefined) {
            if (this.state.liste_generale_assure.length > 0) {
                let liste = this.state.liste_generale_assure.filter(function (el) {
                    return el.idParentAss == idParentAss;
                });
                liste_filtre = liste_filtre.concat(liste);
            }
        }
        this.setState({ lassure_rech: liste_filtre });
    }


    handleKeyPress = e => {
        if (e.keyCode === 13) {
            this.get_liste_sortie();
        }
    }

    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Toutes les boutiques' }];
        this.myservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }
            if (this.state.userconnected != null && this.state.userconnected != undefined) {
                this.setState({ ridBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } }, () => {
                    this.get_liste_sortie();
                });
            }
            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);

            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = lboutique_rech.concat(liste);
                }
            }
            this.setState({ lboutique_rech: lboutique_rech })
        }));
    }

    get_liste_client() {
        let lclient = null;
        this.setState({ showindicator: true });
        this.myservice.getListe_client(this.state.rfiltreClient).then(data => this.setState({ liste_client: data }, () => {
            this.setState({ showindicator: false });
            let lclient_rech = [{ value: 'Tous', label: 'Toutes les clients', remiseCli: 0, soldeCli_format: '0', telCli: 'x', tvaCli: 0, balanceCli: 0, caventeCli: 0, label_rech: 'Tous' }];
            if (this.state.liste_client != null && this.state.liste_client != undefined) {
                lclient = this.state.liste_client.map(x => {
                    return { value: x.idCli, label: x.nomCli, remiseCli: x.remiseCli, soldeCli_format: x.soldeCli_format, telCli: x.telCli, tvaCli: x.tvaCli, balanceCli: x.balanceCli, caventeCli: x.caventeCli, label_rech: x.nomCli + ' ' + x.idCli }
                });
                lclient_rech = lclient_rech.concat(lclient);
                this.setState({ lclient: lclient });
                this.setState({ lclient_rech: lclient_rech });
            }
        }));
    }




    get_liste_sortie() {
        let typesortie = 'Facture';
        this.setState({ showindicator: true, liste_sortie: [] });
        let rstatut_livraison = 'Tous';
        let rtype_client = 'Tous';
        let rstatut_reglement = 'Tous';
        let rboutique = 'Tous';
        let rid_client = 'Tous';
        let rnum_facture = 'Tous';
        let rlivreur = 'Tous';
        let rid_parent_assure = 'Tous';
        let rid_assure = 'Tous';
        let rtag = 'Tous';
        if (this.state.rtagSortie != undefined || this.state.rtagSortie != null) {
            rtag = this.state.rtagSortie.value;
        }
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.ridBoutiq != undefined || this.state.ridBoutiq != null) {
            rboutique = this.state.ridBoutiq.value;
        }
        if (this.state.rtypeClient != undefined || this.state.rtypeClient != null) {
            rtype_client = this.state.rtypeClient.value;
        }
        if (this.state.rstatutReglement != undefined || this.state.rstatutReglement != null) {
            rstatut_reglement = this.state.rstatutReglement.value;
        }
        if (this.state.rstatutLivraison != undefined || this.state.rstatutLivraison != null) {
            rstatut_livraison = this.state.rstatutLivraison.value;
        }

        if (this.state.ridClient != undefined || this.state.ridClient != null) {
            rid_client = this.state.ridClient.value;
        }
        if (this.state.ridParent_assure != undefined || this.state.ridParent_assure != null) {
            rid_parent_assure = this.state.ridParent_assure.value;
        }
        if (this.state.ridAssure != undefined || this.state.ridAssure != null) {
            rid_assure = this.state.ridAssure.value;
        }
        if (this.state.ridLivreur != undefined || this.state.ridLivreur != null) {
            rlivreur = this.state.ridLivreur.value;
        }
        if (this.state.rnum_facture != undefined || this.state.rnum_facture != null) {
            rnum_facture = this.state.rnum_facture.value;
        }

        this.setState({ total_facture: '0' });
        this.setState({ total_gain: '********' });
        this.setState({ total_restant: '0' });

        this.myservice.get_liste_sortie_by_criteria(spd1, spd2, rnum_facture, rboutique, rstatut_reglement, rstatut_livraison, rid_client, rid_parent_assure, rid_assure, rlivreur, typesortie, rtype_client, rtag).then(data => this.setState({ liste_sortie: data, showindicator: false }, () => {
            let initialValue = 0
            if (data != null && data != undefined) {
                let tt_facture = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.montant_sortie
                }, initialValue);
                let tt_restant = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.mt_restant
                }, initialValue);
                let tt_marge = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.mtgain
                }, initialValue);

                this.setState({ total_facture: this.formaterMontant(tt_facture) });
                if (this.state.userconnected.profilUser === 'PDG') {
                    this.setState({ total_gain: this.formaterMontant(tt_marge) });
                } else {
                    this.setState({ total_gain: '******' });
                }
                this.setState({ total_restant: this.formaterMontant(tt_restant) });
            }

        }));
    }

    assureTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-10">
                            <label style={{ color: '#000000', fontWeight: 'bold' }}>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.matrAss}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    enreg_reglSortie_bloc() {
        if (this.state.liste_sortie_for_reglement_selecteds === null || this.state.liste_sortie_for_reglement_selecteds === undefined || this.state.daterglSortie === '' || this.state.daterglSortie === undefined || this.state.daterglSortie === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner les factures à régler' });
        } else {
            if (this.state.liste_sortie_for_reglement_selecteds.length <= 0) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les factures à régler' });
                return;
            } else if (this.state.ridClient === null || this.state.ridClient === undefined) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un client' });
                return;
            } else if (this.state.ridClient.value === '0' || this.state.ridClient.value === 'Tous') {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un client' });
                return;
            }
            if (this.myservice.get_statut_offline() === 'true' && this.state.modeRgl.value === 'Compte') {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez régler par compte en mode offline' });
                return;
            }
            this.myservice.regler_sortie_bloc(this.state.liste_sortie_for_reglement_selecteds, this.state.modeRgl.value, this.state.refRgl, this.myservice.get_statut_sync()).then(reponse => {
                if (reponse != null && reponse != undefined) {
                    if (reponse.code === 200) {
                        this.setState({ total_reglement_format: '0' });
                        this.setState({ liste_sortie_for_reglement_selecteds: [] });
                        this.get_liste_sortie_for_reglement();
                        this.growl.show({ severity: 'success', summary: 'Success', detail: reponse.contenu });
                    } else {
                        this.growl.show({ severity: 'error', summary: 'Error', detail: reponse.contenu });

                    }
                } else {
                    this.growl.show({ severity: 'error', summary: 'Error', detail: 'Erreur inconnue' });
                }
            });
        }
    }

    annule_reglSortie_bloc() {
        this.setState({ total_reglement_format: '0' });
        this.setState({ daterglSortie: '' });
        this.setState({ refRglSortie: '' });
        this.forceUpdate();
    }

    annule_depot() {
        this.setState({ montantOpcli: 0 });
        this.setState({ confmontantOpcli: 0 });
        this.setState({ descOpcli: '' });
        this.forceUpdate();
    }


    resultat_reglSortie(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_reglSortie();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    conf_print_facture(data, format) {
        this.myservice.generer_edition_facture(data.idSortie, data.typeSortie, format);
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    actionTemplate(rowData, column) {
        let items = [
            {
                label: 'Facture',
                icon: 'pi pi-file',
                command: (e) => {
                    this.conf_print_facture(rowData, 'A4');
                }
            },
            {
                label: 'Ticket',
                icon: 'pi pi-print',
                command: (e) => {
                    this.conf_print_facture(rowData, 'Ticket');
                }
            }
        ]

        let label = rowData.ordre + '';
        return <div>
            <SplitButton label={label} onClick={(e) => this.conf_print_facture(rowData, 'A4')} icon="pi pi-print" model={items}></SplitButton>
        </div>
    }

    btn() {
        return <center>
            <div className="p-grid" style={{ width: 200 }}>
                <div className="p-col"><SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_sortie()} icon="pi pi-search" model={this.state.items}></SplitButton></div>
                <div className="p-col">  {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
            </div>
        </center>
    }
    option_recherche() {
        return <div className="p-grid">
            <div className="p-col-12 p-md-2">
                <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
            </div>
            <div className="p-col-12 p-md-2">
                <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
            </div>
            <div className="p-col-12 p-md-3">
                <Dropdown id="new_dp_client_rech"
                    options={this.state.lclient_rech}
                    value={this.state.ridClient}
                    placeholder="Client"
                    onChange={(e) => this.setState({ ridClient: e.value, idCli_situation: e.value }, () => {
                        this.get_liste_sortie();
                        if (this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie') {
                            this.select_parent_assure_by_client(this.state.ridClient.value);
                        }
                    })}
                    style={{ width: '100%' }}
                    panelStyle={{ width: '500px' }} optionLabel='label_rech' filter={true} filterBy='label_rech' filterMatchMode='contains' autoWidth={false} itemTemplate={this.clientItemTemplate}
                />
            </div>

            <div className="p-col-12 p-md-2">
                <Dropdown options={this.state.lboutique_rech} placeholder="Toutes les établissements" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value }, () => {
                    this.get_liste_sortie();
                })} autoWidth={false} />
            </div>

            <div className="p-col-12 p-md-2">
                {this.btn()}
            </div>
        </div>

    }

    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_situation_client: false, visible_dlg_reglement: false, visible_dlg_depot: false })} label="Fermer" className="p-button-secondary" />
            </div>
        );


        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />

                <Dialog header="Situation client" visible={this.state.visible_situation_client} style={{ width: '90%' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible_situation_client: false })}>
                    <FormSituation_client client={this.state.idCli_situation} />
                </Dialog>

                <OverlayPanel ref={(el) => { this.op = el; }} style={{ width: '250px' }} >
                    <div className="p-grid">
                        <div className="p-col-10">
                            <InputText value={this.state.rfiltreClient} onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.op.toggle(e);
                                    document.getElementById("new_dp_client").click();
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-warn" onClick={(e) => {
                                this.op.toggle(e);
                                document.getElementById("new_dp_client").click();
                                this.get_liste_client();
                            }} style={{ width: '30px' }} />
                        </div>
                    </div>
                </OverlayPanel>
                <OverlayPanel ref={(el) => { this.opdepot = el; }} style={{ width: '250px' }} >
                    <div className="p-grid">
                        <div className="p-col-10">
                            <InputText value={this.state.rfiltreClient} onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.opdepot.toggle(e);
                                    document.getElementById("new_dp_client_depot").click();
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-warn" onClick={(e) => {
                                this.opdepot.toggle(e);
                                document.getElementById("new_dp_client_depot").click();
                                this.get_liste_client();
                            }} style={{ width: '30px' }} />
                        </div>
                    </div>
                </OverlayPanel>
                <OverlayPanel ref={(el) => { this.oprgl = el; }} style={{ width: '250px' }} >
                    <div className="p-grid">
                        <div className="p-col-10">
                            <InputText value={this.state.rfiltreClient} onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.oprgl.toggle(e);
                                    document.getElementById("new_dp_client_rgl").click();
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-warn" onClick={(e) => {
                                this.oprgl.toggle(e);
                                document.getElementById("new_dp_client_rgl").click();
                                this.get_liste_client();
                            }} style={{ width: '30px' }} />
                        </div>
                    </div>
                </OverlayPanel>
                <OverlayPanel ref={(el) => { this.oprech = el; }} style={{ width: '250px' }} >
                    <div className="p-grid">
                        <div className="p-col-10">
                            <InputText value={this.state.rfiltreClient} onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.oprech.toggle(e);
                                    document.getElementById("new_dp_client_rech").click();
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-warn" onClick={(e) => {
                                this.oprech.toggle(e);
                                document.getElementById("new_dp_client_rech").click();
                                this.get_liste_client();
                            }} style={{ width: '30px' }} />
                        </div>
                    </div>
                </OverlayPanel>
                <Dialog header="Dépot" visible={this.state.visible_dlg_depot} style={{ width: '40%' }} modal={false} footer={dialogFooter} onHide={(e) => this.setState({ visible_dlg_depot: false })}>
                    <div className="p-grid" style={{ backgroundColor: '#f7f7f7' }}>
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ marginBottom: '-7px', backgroundColor: '#f7f7f7' }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="adrCli">Client *</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <div className="p-grid">
                                            <div className="p-col-11">
                                                <Dropdown id="new_dp_client_depot"
                                                    options={this.state.lclient}
                                                    value={this.state.ridClient}
                                                    placeholder="Client"
                                                    onChange={(e) => this.setState({ ridClient: e.value, idCli_situation: e.value })}
                                                    style={{ width: '100%' }}
                                                    panelStyle={{ width: '500px' }} optionLabel='label_rech' filter={true} filterBy='label_rech' filterMatchMode='contains' autoWidth={false} itemTemplate={this.clientItemTemplate}
                                                />
                                            </div>
                                            <div className="p-col-1">
                                                <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                                    this.opdepot.toggle(e);
                                                }} style={{ marginLeft: -15, width: '30px' }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="adrCli">Montant *</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <Cleave value={this.state.montantOpcli} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ montantOpcli: e.target.rawValue })} className="p-inputtext" />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="adrCli">Confirmer Montant *</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <Cleave value={this.state.confmontantOpcli} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ confmontantOpcli: e.target.rawValue })} className="p-inputtext" />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="cbArticle">Commentaire </label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <InputTextarea rows={2} cols={30} id="cbArticle" value={this.state.descOpcli} onChange={(e) => this.setState({ descOpcli: e.target.value })} />
                                    </div>
                                </div>
                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_depot()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_depot()} /></div>
                                        <div className="p-col">{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>

                </Dialog>

                <Dialog header="Règlement en bloc" visible={this.state.visible_dlg_reglement} style={{ width: '85%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_reglement: false })} footer={dialogFooter}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 430, marginBottom: '10px', backgroundColor: '#f7f7f7' }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-1">
                                        <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-grid">
                                            <div className="p-col-11">
                                                <Dropdown id="new_dp_client_rgl"
                                                    options={this.state.lclient}
                                                    value={this.state.ridClient}
                                                    placeholder="Client"
                                                    onChange={(e) => this.setState({ ridClient: e.value, idCli_situation: e.value }, () => {
                                                        this.get_liste_sortie_for_reglement();
                                                        if (this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie') {
                                                            this.select_parent_assure_by_client(this.state.ridClient.value);
                                                        }
                                                    })}
                                                    style={{ width: '100%' }}
                                                    panelStyle={{ width: '500px' }} optionLabel='label_rech' filter={true} filterBy='label_rech' filterMatchMode='contains' autoWidth={false} itemTemplate={this.clientItemTemplate}
                                                />
                                            </div>
                                            <div className="p-col-1">
                                                <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                                    this.oprgl.toggle(e);
                                                }} style={{ marginLeft: -15, width: '30px' }} />
                                            </div>
                                        </div>


                                    </div>
                                    {this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie' ?
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown style={{ width: '90%' }} options={this.state.lparent_assure_rech} placeholder="Souscripteur" value={this.state.ridParent_assure} optionLabel='label_rech' filter={true} filterBy='label_rech' filterMatchMode='contains' onChange={(e) => this.setState({ ridParent_assure: e.value }, () => {
                                                if (this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie') {
                                                    this.select_assure_by_parent(this.state.ridParent_assure.value);
                                                }
                                            })} autoWidth={false} panelStyle={{ width: '550px' }} />
                                        </div>
                                        : null}
                                    {this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie' ?
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown style={{ width: '90%' }} options={this.state.lassure_rech} placeholder="Assuré" value={this.state.ridAssure} optionLabel='label_rech' filter={true} filterBy='label_rech' filterMatchMode='contains' onChange={(e) => this.setState({ ridAssure: e.value })} autoWidth={false} panelStyle={{ width: '550px' }} itemTemplate={this.assureTemplate} />
                                        </div>
                                        : null}
                                    <div className="p-col-12 p-md-2">
                                        <div className="p-inputgroup">
                                            <Button icon='pi pi-search' label='Rechercher' style={{ width: '120px' }} onClick={(e) => {
                                                this.get_liste_sortie_for_reglement()
                                            }} />
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </div>
                                    </div>
                                </div>
                                <DataTable value={this.state.liste_sortie_for_reglement} scrollable={true} scrollHeight="300px"
                                    onSelectionChange={e => {
                                        this.setState({ total_reglement_format: '0' });
                                        this.setState({ liste_sortie_for_reglement_selecteds: e.value }, () => {
                                            let total = 0;
                                            if (this.state.liste_sortie_for_reglement_selecteds != null && this.state.liste_sortie_for_reglement_selecteds != undefined) {
                                                if (this.state.liste_sortie_for_reglement_selecteds.length > 0) {
                                                    for (var i = this.state.liste_sortie_for_reglement_selecteds.length; i--;) {
                                                        total += this.state.liste_sortie_for_reglement_selecteds[i].mt_restant;
                                                    }
                                                }
                                            }
                                            this.setState({ total_reglement_format: this.formaterMontant(total) });
                                        });
                                    }
                                    } selection={this.state.liste_sortie_for_reglement_selecteds}
                                    responsive={true} style={{ marginBottom: 12 }}>
                                    <Column selectionMode="multiple" style={{ width: '5%' }} />
                                    <Column field="dateSortie_format" header="Date" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="numFactureSortie" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#F34C1F', fontWeight: 'bold' }}>{rowData.numFactureSortie}</span>
                                        </div>
                                    }} header="Facture" style={{ width: '14%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="montant_sortie" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000', fontWeight: 'bold' }}>{rowData.montant_sortie_format}</span>
                                        </div>
                                    }} header="Montant" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="mt_restant" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'red', fontWeight: 'bold' }}>{rowData.mt_restant_format}</span>
                                        </div>
                                    }} header="Restant" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="nomComplet" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ fontWeight: 'bold' }}>{rowData.idCli.nomCli} / </span>
                                            <span>{rowData.nom_souscripteur}</span><br />
                                            <span style={{ fontWeight: 'bold', color: '#F34C1F' }}>{rowData.nom_assure}</span>
                                        </div>
                                    }} header="Client" style={{ width: '40%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable>

                            </div>
                            <div className="p-grid" style={{ backgroundColor: '#f7f7f7' }}>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="libCodif">Date *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.daterglSortie} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ daterglSortie: e.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="libCodif">Montant total </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <label style={{ fontWeight: 'bold', fontSize: '18px', color: 'green' }}>{this.state.total_reglement_format} </label>
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descParamCodif">Référence </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText value={this.state.refRgl} onChange={(e) => this.setState({ refRgl: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descParamCodif">Mode reglement *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown placeholder="Mode reglement" options={this.state.liste_mode_reglement} value={this.state.modeRgl} optionLabel='label' onChange={(e) => {
                                        this.setState({ modeRgl: e.value })
                                    }
                                    } autoWidth={false} />
                                </div>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_reglSortie_bloc()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_reglSortie_bloc()} /></div>
                                </div>
                            </center>
                        </div>
                    </div>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: this.state.min_height, marginBottom: -5 }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                            <hr />
                            {this.option_recherche()}




                            <DataTable value={this.state.liste_sortie} selectionMode="single" paginatorPosition="top" paginator={true} rows={this.state.nb_row} rowsPerPageOptions={[5, 10, 20, 100, 200]}
                                onRowClick={e => this.setState({ selectedSortie: e.data })} onRowSelect={e => this.setState({ selectedSortie: e.data })} responsive={true} selection={this.state.selectedSortie}
                            >
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%', overflow: 'visible' }} ref={(el3) => { this.dt3 = el3; }} />
                                <Column field="numFactureSortie" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#F34C1F', fontWeight: 'bold' }}>{rowData.numFactureSortie}</span><br />
                                    </div>
                                }} header="N° sortie" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="dateSortie" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idCli.nomCli" body={(rowData, column) => {
                                    let nom_souscripteur = rowData.nom_souscripteur;
                                    let nom_assure = rowData.nom_assure;
                                    if (nom_assure === '-') {
                                        nom_assure = '';
                                    }
                                    if (nom_souscripteur === '-') {
                                        nom_souscripteur = '';
                                    }
                                    return <div>
                                        <span>{rowData.idCli.nomCli}</span>
                                        <span> {nom_souscripteur}</span>
                                        <span> {nom_assure}</span>
                                    </div>
                                }} header="Client" filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="nom_boutique" header="Etablissement" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>

                        </div>

                    </div>

                </div>
            </div>
        );
    }
}