import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment, { utc } from 'moment';
import { SplitButton } from 'primereact/splitbutton';
import Numeral from 'numeral';
import { Panel } from 'primereact/panel';


export class FormEdition_Commande extends Component {
    constructor() {
        super();
        this.state = {
            idCmde: '0',
            libArticle: '',
            mtrglCmde: 0,
            montant_total: 0,
            montant_total_format: '0',
            mtapayer_format: '0',
            total_restant: '0',
            total_avoir: '0',
            total_facture: '0',
            total_gain: '0',
            modeRgl: { label: 'Espèce', value: 'Espèce' },
            refRgl: '',
            daterglCmde: new Date(),
            liste_cmde_for_reglement_selecteds: [],
            liste_cmde_for_reglement: [],
            liste_fournisseur: [],
            pd1: new Date(),
            pd2: new Date(),
            retatCmde: null,
            rtypeCmde: null,
            ridBoutiq: null,
            ridFour: null,
            rstatutCmde: null,
            rtypeFour: { label: 'Type fournisseur', value: 'Tous' },
            liste_cmde: [],
            liste_boutique: [],
            selectedCmde: null,
            msg: '',
            visible_dlg_reglement: false,
            showindicator: false,
            liste_type_fournisseur: [
                { label: 'Type fournisseur', value: 'Tous' },
                { label: 'INTERNE', value: 'INTERNE' },
                { label: 'EXTERNE ', value: 'EXTERNE' },
            ],
            liste_mode_reglement: [
                { label: 'Espèce', value: 'Espèce' },
                { label: 'Chèque', value: 'Chèque' },
                { label: 'Virement', value: 'Virement' },
            ],
            liste_statut_reglement: [
                { label: 'Reglement', value: 'Tous' },
                { label: 'Reglé', value: 'Regle' },
                { label: 'Non reglé', value: 'Non regle' }
            ],
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format brut',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };


        this.state.pd1.setDate(this.state.pd1.getDate() - 30);

        this.conf_print = this.conf_print.bind(this);
        this.show_dlg_reglement = this.show_dlg_reglement.bind(this);
        this.conf_print_bc = this.conf_print_bc.bind(this);
        this.myservice = new Xulaservice();
    }

    conf_print(format) {
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let rstatut_cmde = 'Tous';
        let rid_four = 'Tous';
        let rid_boutique = 'Tous';
        let rtype_four = 'Tous';
        if (this.state.rtypeFour != undefined && this.state.rtypeFour != null) {
            rtype_four = this.state.rtypeFour.value;
        }

        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.ridFour != undefined && this.state.ridFour != null) {
            rid_four = this.state.ridFour.value;
        }
        if (this.state.rstatutCmde != undefined && this.state.rstatutCmde != null) {
            rstatut_cmde = this.state.rstatutCmde.value;
        }

        this.myservice.generer_journal_commande(spd1, spd2, 'Tous', rid_boutique, rstatut_cmde, rid_four, 'Tous', rtype_four, this.get_cible_date(), format);
    }

    show_dlg_reglement() {
        let liste = [];
        if (this.state.liste_cmde.length > 0) {
            liste = this.state.liste_cmde.filter(function (el) {
                return el.mt_restant > 0;
            })
        }
        this.setState({ liste_cmde_for_reglement: liste }, () => {
            this.setState({ visible_dlg_reglement: true });
        });
    }

    componentDidUpdate(prevProps) {

        if (prevProps.four !== this.props.four) {
            if (this.props != undefined) {
                let four = this.props.four;
                if (four != null && four != undefined && four != '') {
                    this.setState({ ridFour: four }, () => {
                        this.get_liste_cmde();
                        return;
                    });
                }

            }
        }
        if (prevProps.match !== undefined) {
            if (prevProps.match.params.dateCible !== this.props.match.params.dateCible) {
                this.get_liste_cmde();
            }
        }
    }

    componentDidMount() {
        this.get_liste_cmde();
        this.get_liste_boutique();
        this.get_liste_fournisseur();
    }

    get_cible_date() {
        let val = 'DATE_CMDE';
        if (this.props.match !== undefined) {
            if (this.props.match.params.dateCible != null && this.props.match.params.dateCible != undefined) {
                if (this.props.match.params.dateCible === 'par_date') {
                    val = 'DATE_CMDE';
                } else {
                    val = 'DELAI_RGL_CMDE';
                }
            }
        }

        return val;
    }

    get_liste_cmde() {
        let rstatut_cmde = 'Tous';
        let rid_four = 'Tous';
        let rid_boutique = 'Tous';
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let rtype_four = 'Tous';
        if (this.state.rtypeFour != undefined && this.state.rtypeFour != null) {
            rtype_four = this.state.rtypeFour.value;
        }

        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.ridFour != undefined && this.state.ridFour != null) {
            rid_four = this.state.ridFour.value;
        }
        if (this.state.rstatutCmde != undefined && this.state.rstatutCmde != null) {
            rstatut_cmde = this.state.rstatutCmde.value;
        }


        this.myservice.get_liste_cmde_by_criteria(spd1, spd2, 'Tous', rid_boutique, rstatut_cmde, rid_four, 'Tous', rtype_four, this.get_cible_date()).then(data => this.setState({ liste_cmde: data, showindicator: false }, () => {
            if (data != null && data != undefined) {
                let tt_facture = 0;
                let tt_restant = 0;
                let tt_marge = 0;
                let tt_avoir = 0;
                for (var i = data.length; i--;)
                    if (data[i].typeCmde !== 'Avoir') {
                        tt_facture = tt_facture + data[i].mtcmde;
                        tt_restant = tt_restant + data[i].mtrest;
                        tt_marge = tt_marge + data[i].mtcmde;
                        tt_facture = tt_facture + data[i].marge_cmde;
                    } else {
                        tt_avoir = tt_avoir + data[i].mtcmde;
                    }
                this.setState({ total_facture: this.formaterMontant(tt_facture) });
                if (this.state.userconnected.profilUser === 'PDG') {
                    this.setState({ total_gain: this.formaterMontant(tt_marge) });
                } else {
                    this.setState({ total_gain: '******' });
                }
                this.setState({ total_restant: this.formaterMontant(tt_restant) });
                this.setState({ total_avoir: this.formaterMontant(tt_avoir) });
            }
        }));
    }


    get_liste_boutique() {
        this.myservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }));
    }

    get_liste_fournisseur() {
        this.myservice.getListe_fournisseur().then(data => this.setState({ liste_fournisseur: data }));
    }

    get_liste_cmde_for_reglement() {
        let rid_four = 'Tous';
        let rid_boutique = 'Tous';
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.ridFour != undefined && this.state.ridFour != null) {
            rid_four = this.state.ridFour.value;
        }

        this.myservice.get_liste_cmde_by_criteria(spd1, spd2, 'Tous', rid_boutique, 'Non regle', rid_four, 'Tous', 'Tous', 'DATE_CMDE').then(data => this.setState({ liste_cmde_for_reglement: data, showindicator: false }));

    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_cmde_for_reglement();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }


    conf_print_bc(data) {
        this.myservice.generer_edition_bc(data.idCmde);
    }



    annule_reglCmde_bloc() {
        this.setState({ daterglCmde: '' });
        this.setState({ visible_dlg_reglement: false });
        this.setState({ mtrglCmde: 0 });
        this.setState({ montant_total: '0' });
    }


    actionTemplate(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.conf_print_bc(rowData)} style={{ width: 25, height: 25 }} icon="pi pi-print" /></div>
        </div>
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    enreg_reglCmde_bloc() {
        if (this.state.liste_cmde_for_reglement_selecteds === null || this.state.liste_cmde_for_reglement_selecteds === undefined || this.state.daterglCmde === '' || this.state.daterglCmde === undefined || this.state.daterglCmde === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner les factures à régler' });
        } else {
            if (this.state.liste_cmde_for_reglement_selecteds.length <= 0) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les factures à régler' });
                return;
            }
            this.setState({ showindicator: true });
            let date = moment(this.state.daterglCmde, 'DD/MM/YYYY').format('DD/MM/YYYY');
            this.myservice.regler_cmde_bloc(this.state.liste_cmde_for_reglement_selecteds, this.state.modeRgl.value, this.state.refRgl, date).then(reponse => {
                if (reponse != null && reponse != undefined) {
                    if (reponse.code === 200) {
                        this.setState({ total_reglement_format: '0' });
                        this.setState({ liste_cmde_for_reglement_selecteds: [] });
                        this.get_liste_cmde_for_reglement();
                        this.growl.show({ severity: 'success', summary: 'Success', detail: reponse.contenu });
                    } else {
                        this.growl.show({ severity: 'error', summary: 'Error', detail: reponse.contenu });

                    }
                } else {
                    this.growl.show({ severity: 'error', summary: 'Error', detail: 'Erreur inconnue' });
                }
                this.setState({ showindicator: false });
            });
        }
    }


    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" onClick={() => {
                    this.setState({ visible_dlg_reglement: false });
                }} label="Annuler" className="p-button-secondary" />
            </div>
        );
        let lfournisseur = null;
        let lfournisseur_rech = [{ value: 'Tous', label: 'Fournisseur', typeFour: 'NA', tvaFour: 0 }];

        if (this.state.liste_fournisseur != null && this.state.liste_fournisseur != undefined) {
            lfournisseur = this.state.liste_fournisseur.map(x => {
                return { value: x.idFour, label: x.nomFour, typeFour: x.typeFour, tvaFour: x.tvaFour }
            });
            lfournisseur_rech = lfournisseur_rech.concat(lfournisseur);
        }

        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Etablissement' }];

        if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
            lboutique = this.state.liste_boutique.map(x => {
                return { value: x.idBoutiq, label: x.nomBoutiq }
            });
            lboutique_rech = lboutique_rech.concat(lboutique);
        }

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Règlement en bloc" visible={this.state.visible_dlg_reglement} style={{ width: '70%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_reglement: false })} footer={dialogFooter}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 400, marginBottom: '10px', backgroundColor: '#f7f7f7' }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <Dropdown options={lfournisseur_rech} placeholder="Fournisseur" value={this.state.ridFour} panelStyle={{ width: '500px' }} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridFour: e.value }, () => {
                                            this.get_liste_cmde_for_reglement();
                                        })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <div className="p-inputgroup">
                                            <Button icon='pi pi-search' label='Rechercher' style={{ width: '120px' }} onClick={(e) => {
                                                this.get_liste_cmde_for_reglement()
                                            }} />
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </div>
                                    </div>
                                </div>
                                <DataTable value={this.state.liste_cmde_for_reglement} scrollable={true} scrollHeight="300px"
                                    onSelectionChange={e => {
                                        this.setState({ total_reglement_format: '0' });
                                        this.setState({ liste_cmde_for_reglement_selecteds: e.value }, () => {
                                            let total = 0;
                                            if (this.state.liste_cmde_for_reglement_selecteds != null && this.state.liste_cmde_for_reglement_selecteds != undefined) {
                                                if (this.state.liste_cmde_for_reglement_selecteds.length > 0) {
                                                    for (var i = this.state.liste_cmde_for_reglement_selecteds.length; i--;) {
                                                        total += this.state.liste_cmde_for_reglement_selecteds[i].mt_restant;
                                                    }
                                                }
                                            }
                                            this.setState({ total_reglement_format: this.formaterMontant(total) });
                                        });
                                    }
                                    } selection={this.state.liste_cmde_for_reglement_selecteds}
                                    responsive={true} style={{ marginBottom: 12 }}>
                                    <Column selectionMode="multiple" style={{ width: '5%' }} />
                                    <Column body={(rowData, column) => {
                                        let color = 'black';
                                        if (rowData.avoir.idcmde != null && rowData.avoir.idcmde != undefined) {
                                            color = 'blue';
                                        }
                                        return <div>
                                            <span style={{ color: color, fontWeight: 'bold' }}>{rowData.numCmde}</span> <br />
                                        </div>
                                    }} header="N° BC" style={{ width: '15%' }} />
                                    <Column field="dateCmde" header="Date" style={{ width: '10%' }} />
                                    <Column field="typeCmde" header="Type" style={{ width: '10%' }} />
                                    <Column body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.mt_format}</span>
                                        </div>
                                    }} header="Montant" style={{ width: '15%' }} />
                                    <Column body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'red' }}>{rowData.mt_restant_format}</span>
                                        </div>
                                    }} header="Restant" style={{ width: '15%' }} />
                                    <Column field="idFour.nomFour" header="Fournisseur" style={{ width: '34%' }} />
                                </DataTable>

                            </div>
                            <div className="p-grid" style={{ backgroundColor: '#f7f7f7' }}>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="libCodif">Date *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.daterglCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ daterglCmde: e.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="libCodif">Montant total </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <label style={{ fontWeight: 'bold', fontSize: '18px', color: 'green' }}>{this.state.total_reglement_format} </label>
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descParamCodif">Référence </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText value={this.state.refRgl} onChange={(e) => this.setState({ refRgl: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descParamCodif">Mode reglement *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown placeholder="Mode reglement" options={this.state.liste_mode_reglement} value={this.state.modeRgl} optionLabel='label' onChange={(e) => {
                                        this.setState({ modeRgl: e.value })
                                    }
                                    } autoWidth={false} />
                                </div>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_reglCmde_bloc()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_reglCmde_bloc()} /></div>
                                </div>
                                <div className="p-col">  {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                            </center>
                        </div>
                    </div>
                </Dialog>


                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '600px', marginBottom: -5 }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                         <hr />
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={lboutique_rech} placeholder="Etablissement" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value }, () => {
                                                this.get_liste_cmde();
                                            })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={lfournisseur_rech} placeholder="Fournisseur" value={this.state.ridFour} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridFour: e.value }, () => {
                                                this.get_liste_cmde();
                                            })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={this.state.liste_type_fournisseur} placeholder="Type fournisseur" value={this.state.rtypeFour} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rtypeFour: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={this.state.liste_statut_reglement} placeholder="Reglement" value={this.state.rstatutCmde} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rstatutCmde: e.value })} autoWidth={false} />
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <center>
                                <div className="p-grid" style={{ width: 400 }}>
                                    <div className="p-col"><SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_cmde()} icon="pi pi-search" model={this.state.items}></SplitButton></div>
                                    <div className="p-col"> <Button type="button" icon="pi pi-briefcase" className="p-button-success" iconPos="left" label="Règlement" style={{ width: 140 }} onClick={this.show_dlg_reglement}></Button></div>
                                    <div className="p-col">  {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                </div>
                            </center>

                            <Panel style={{ backgroundColor: '#f7f7f7' }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Total</label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}> {this.state.total_facture} </label>
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Restant</label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'red' }}> {this.state.total_restant} </label>
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Marge prév.</label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'green' }}> {this.state.total_gain} </label>
                                    </div>

                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Total avoir</label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'green' }}> {this.state.total_avoir} </label>
                                    </div>
 
                                </div>
                            </Panel>
                            <DataTable value={this.state.liste_cmde} selectionMode="single" paginatorPosition="top" paginator={true} rows={7} rowsPerPageOptions={[5, 7, 10, 20, 100, 200]}
                                onRowClick={e => this.setState({ selectedCmde: e.data })} onRowSelect={e =>
                                    this.setState({ selectedCmde: e.data })} responsive={true} selection={this.state.selectedCmde} csvSeparator=";" ref={(el) => { this.dt = el; }} >
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '3%', overflow: 'visible' }} />
                                <Column field="numCmde" body={(rowData, column) => {

                                    let color = 'black';
                                    if (rowData.avoir !== null && rowData.avoir !== undefined && rowData.avoir !== '') {
                                        if ((rowData.avoir.idCmde !== '' && rowData.avoir.idCmde !== undefined && rowData.avoir.idCmde !== null) || rowData.typeCmde === 'Avoir') {
                                            color = 'blue';
                                        }
                                    }

                                    return <div>
                                        <span style={{ color: color, fontWeight: 'bold' }}>{rowData.numCmde}</span> <br />
                                    </div>
                                }} header="N° BC" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="dateCmde" header="Date" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="statutCmde" header="Statut" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="typeCmde" header="Type" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="mtcmde" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.mt_format}</span>
                                    </div>
                                }} header="Montant" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="mtrest" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red' }}>{rowData.mt_restant_format}</span>
                                    </div>
                                }} header="Restant" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idFour.nomFour" header="Fournisseur" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idBoutiq.nomBoutiq" header="Etablissement" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>

                        </div>

                    </div>

                </div>
            </div >
        );
    }
}