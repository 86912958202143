import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import Cleave from 'cleave.js/react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import Numeral from 'numeral';


export class FormPersonnel extends Component {
    constructor() {
        super();
        this.state = {
            liste_personnel: [],
            liste_boutique: [],
            liste_pers_selected: [],
            selectedPers: null,
            matrPers: '',
            nomPers: '',
            telPers: '',
            emailPers: '',
            adrPers: '',
            photoPers: 'nophoto.png',
            fonctionPers: { label: 'Gérant', value: 'Gérant' },
            salairePers: 0,
            dateDepartPers: null,
            capturePieceCli: 'NA',
            dateArvPers: new Date(),
            motifDepart: '',
            statutPers: 'En poste',
            liste_statut: [
                { label: 'En poste', value: 'En poste' },
                { label: 'Licencié', value: 'Licencié' },
                { label: 'En attente', value: 'En attente' }
            ],
            numPiPers: '',
            typePiPers: '',
            capturePiPers: '',
            tuteurPers: '',
            adrTuteurPers: '',
            captureEngPers: '',
            liste_fonction: [
                { label: 'Agent', value: 'Agent' },
                { label: 'PDG', value: 'PDG' },
                { label: 'DRH', value: 'DRH' },
                { label: 'Caissier', value: 'Caissier' },
                { label: 'Chauffeur', value: 'Chauffeur' },
                { label: 'Assistant', value: 'Assistant' },
                { label: 'Gérant', value: 'Gérant' },
                { label: 'Coordinateur', value: 'Coordinateur' },
                { label: 'Directeur général', value: 'Directeur général' },
                { label: 'Commercial', value: 'Commercial' },
                { label: 'Comptable', value: 'Comptable' },
                { label: 'Chef de production', value: 'Chef de production' },
                { label: 'Magasinier', value: 'Magasinier' },
                { label: 'Vigil', value: 'Vigil' },
                { label: 'Nettoyage', value: 'Nettoyage' },
                { label: 'Autre', value: 'Autre' },
            ],
            file: null,
            msg: '',
            modif: false,
            visible: false,
            idBoutiq: null,
            visible_print: false,
            showindicator: false ,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),

        };

        this.edit_pers = this.edit_pers.bind(this);
        this.conf_delete_pers = this.conf_delete_pers.bind(this);
        this.enreg_pers = this.enreg_pers.bind(this);
        this.onChange = this.onChange.bind(this);
        this.print_badge = this.print_badge.bind(this);
        this.toogle_print = this.toogle_print.bind(this);
        this.xulaservice = new Xulaservice();
    }

    toogle_print() {
        this.setState({ visible_print: !this.visible_print });
    }

    onChange(e) {
        this.setState({ file: e.target.files[0] })
    }

    print_badge() {
        if (this.state.liste_pers_selected.length <= 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à imprimer' });
        } else {
            let ids = this.state.liste_pers_selected.map(x => {
                return x.matrPers
            }).join("__");
            this.xulaservice.generer_badge(ids);
        }

    }


    fileUpload(file, namefile) {
        this.xulaservice.upload_file(file, namefile, 'photos').then(data => {
            if (data.code == '200') {
                this.growl.show({ severity: 'success', summary: 'Success', detail: 'Chargement effectué avec succès' });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: 'Echec upload' });
            }
        });
    }

    componentDidMount() {
        this.get_liste_boutique();
        this.get_liste_personnel();
    }

    get_liste_boutique() {
        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }));
    }

    get_liste_personnel() {
        this.setState({ showindicator: true });
        this.xulaservice.get_liste_personnel().then(data => this.setState({ liste_personnel: data, showindicator: false }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_pers();
            this.get_liste_personnel();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_pers(data) {
        if (data != null && data != undefined) {
            this.setState({ matrPers: data.matrPers });
            this.setState({ nomPers: data.nomPers });
            this.setState({ telPers: data.telPers });
            this.setState({ emailPers: data.emailPers });
            this.setState({ adrPers: data.adrPers });
            this.setState({ fonctionPers: { value: data.fonctionPers, label: data.fonctionPers } });
            this.setState({ salairePers: data.salairePers });
            this.setState({ idBoutiq: { value: data.idBoutiq.idBoutiq, label: data.idBoutiq.nomBoutiq } });
            this.setState({ dateDepartPers: data.dateDepartPers });
            this.setState({ dateArvPers: data.dateArvPers });
            this.setState({ motifDepart: data.motifDepart });
            this.setState({ statutPers: data.statutPers });
            this.setState({ numPiPers: data.numPiPers });
            this.setState({ typePiPers: data.typePiPers });
            this.setState({ capturePiPers: data.capturePiPers });
            this.setState({ tuteurPers: data.tuteurPers });
            this.setState({ adrTuteurPers: data.adrTuteurPers });
            this.setState({ captureEngPers: data.captureEngPers });
            this.setState({ photoPers: data.photoPers });
            this.setState({ selectedPers: data });
            this.setState({ modif: true });
            window.scrollTo(0, 0);
        }
    }

    annule_pers() {
        this.setState({ matrPers: '' });
        this.setState({ photoPers: 'nophoto.png' });
        this.setState({ nomPers: '' });
        this.setState({ telPers: '' });
        this.setState({ emailPers: '' });
        this.setState({ adrPers: '' });
        this.setState({ fonctionPers: '' });
        this.setState({ salairePers: '' });
        this.setState({ dateDepartPers: '' });
        this.setState({ dateArvPers: '' });
        this.setState({ motifDepart: '' });
        this.setState({ numPiPers: '' });
        this.setState({ capturePiPers: '' });
        this.setState({ tuteurPers: '' });
        this.setState({ adrTuteurPers: '' });
        this.setState({ captureEngPers: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_pers() {
        if (this.state.nomPers === "" || this.state.nomPers === undefined || this.state.idBoutiq === "" || this.state.idBoutiq === undefined || this.state.fonctionPers === "" || this.state.fonctionPers === undefined ||
            this.state.statutPers === undefined || this.state.statutPers === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let upload = false;
            let nomfic = this.state.photoPers;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                nomfic = 'PERS_'+this.state.userconnected.idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload = true;
            }

            if (this.state.modif === false) {
                let date_arv = moment(this.state.dateArvPers, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let date_dep = moment(this.state.dateArvPers, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = {
                    matrPers: '0',
                    nomPers: this.state.nomPers,
                    telPers: this.state.telPers, emailPers: this.state.emailPers,
                    adrPers: this.state.adrPers, fonctionPers: this.state.fonctionPers.value,
                    salairePers: this.state.salairePers, dateDepartPers: date_dep,
                    dateArvPers: date_arv, motifDepart: this.state.motifDepart,
                    statutPers: this.state.statutPers, numPiPers: this.state.numPiPers,
                    typePiPers: this.state.typePiPers, capturePiPers: this.state.capturePiPers,
                    tuteurPers: this.state.tuteurPers, adrTuteurPers: this.state.adrTuteurPers,
                    captureEngPers: this.state.captureEngPers,
                    idBoutiq: { idBoutiq: this.state.idBoutiq.value }
                }
                this.setState({ showindicator: true });
                this.xulaservice.enreg_personnel(data)
                    .then(data => {
                        if (data != undefined && data != null) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                            this.resultat(data.code, data.contenu);
                        }
                    });
            } else {
                let date_arv = moment(this.state.dateArvPers, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let date_dep = moment(this.state.dateArvPers, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = this.state.selectedPers;
                data.dateArvPers = date_arv;
                data.dateDepartPers = date_dep;
                data.nomPers = this.state.nomPers;
                data.photoPers = nomfic;
                data.telPers = this.state.telPers;
                data.emailPers = this.state.emailPers;
                data.fonctionPers = this.state.fonctionPers.value;
                data.statutPers = this.state.statutPers
                data.capturePiPers = this.state.capturePiPers;
                data.motifDepart = this.state.motifDepart;
                data.salairePers = this.state.salairePers;
                data.numPiPers = this.state.numPiPers;
                data.adrTuteurPers = this.state.adrTuteurPers;
                data.adrPers = this.state.adrPers;
                data.idBoutiq = { idBoutiq: this.state.idBoutiq.value };
                this.setState({ showindicator: true });
                this.xulaservice.enreg_personnel(data)
                    .then(data => {
                        if (data != undefined && data != null) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }

                            this.resultat(data.code, data.contenu);
                        }
                    });
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={() => this.edit_pers(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={() => this.conf_delete_pers(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }


    photoTemplate(rowData, column) {
        let fic = rowData.photoPers;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.xulaservice.download_file(fic, 'photos')} alt={rowData.nomPers} style={{ width: 50, height: 50 }} />

    }


    delete_pers() {
        if (this.state.selectedPers == null || this.state.selectedPers == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_personnel(this.state.selectedPers).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_pers(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedPers: data });
            this.setState({ msg: data.nomPers });
            this.setState({ visible: true });
        }
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    handleKeyPress = e => {
        if (e.keyCode === 13) {
            this.enreg_pers();
        }
    }

    render() {

        let lboutique = null;
        if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
            lboutique = this.state.liste_boutique.map(x => {
                return { value: x.idBoutiq, label: x.nomBoutiq }
            });
        }

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_pers();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Edition des badges" visible={this.state.visible_print} style={{ width: '70%' }} modal={true} onHide={(e) => {
                    this.setState({ visible_print: false });
                }} >
                    <div className="p-grid" style={{ width: 350 }}>
                        <div className="p-col">
                            <Button label="Editer badges" style={{ width: '140px' }} onClick={(e) => this.print_badge()} icon="pi pi-print" ></Button>
                        </div>

                    </div>
                    <DataTable value={this.state.liste_personnel}
                        responsive={true} scrollable={true} scrollHeight="200px"
                        onSelectionChange={e => this.setState({ liste_pers_selected: e.value })}
                        selection={this.state.liste_pers_selected}   >
                        <Column selectionMode="multiple" style={{ width: '10%' }} />
                        <Column field="matrPers" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                        <Column header="Photo" body={this.photoTemplate.bind(this)} style={{ width: '10%' }} />
                        <Column field="nomPers" header="Nom" filter={true} filterMatchMode='contains' />
                        <Column field="telPers" header="Telephone" style={{ width: '20%' }} filter={true} filterMatchMode='contains' />
                    </DataTable>

                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="nomPers">Nom *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="nomPers" value={this.state.nomPers} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ nomPers: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="telPers">Telephone </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="telPers" value={this.state.telPers} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ telPers: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="emailPers">Email </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="emailPers" value={this.state.emailPers} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ emailPers: e.target.value })} />
                                </div>
                               
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="photosous">Photo</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <input type="file" onChange={(e) => this.setState({ file: e.target.files[0] })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="fonctionPers">Fonction *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.fonctionPers} options={this.state.liste_fonction} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ fonctionPers: e.value })} autoWidth={false} />
                                </div>
                               
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="dateArvPers">Date arrivée *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar id="dateArvPers" dateFormat="dd/mm/yy" value={this.state.dateArvPers} onChange={e => this.setState({ dateArvPers: new Intl.DateTimeFormat('en-GB').format(e.target.value) })}></Calendar>
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="dateDepartPers">Date départ </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar id="dateDepartPers" dateFormat="dd/mm/yy" value={this.state.dateDepartPers} onChange={e => this.setState({ dateDepartPers: new Intl.DateTimeFormat('en-GB').format(e.target.value) })}></Calendar>
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="motifDepart">Motif depart</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText value={this.state.motifDepart} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ motifDepart: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="statutPers">Statut *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.statutPers} options={this.state.liste_statut} filter={true} onChange={(e) => this.setState({ statutPers: e.target.value })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="idBoutiq">Etablissement *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.idBoutiq} options={lboutique} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ idBoutiq: e.value })} autoWidth={false} />
                                </div>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_pers()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_pers()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>
                            <DataTable value={this.state.liste_personnel} selectionMode="single" scrollable={true} scrollHeight="700px"
                                onRowClick={e => this.setState({ selectedPers: e.data })}
                                header={<Button onClick={(e) => this.toogle_print()} label="Edition badges" style={{ width: 170 }}  icon="pi pi-print"  className="p-button-warning p-button-rounded"/>}
                                responsive={true} selection={this.state.selectedPers} >
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="matrPers" header="Matricule" style={{ width: '12%' }} filter={true} filterMatchMode='contains' />
                                <Column header="Photo" body={this.photoTemplate.bind(this)} style={{ width: '6%' }} />
                                <Column field="nomPers" header="Nom" style={{ width: '30%' }} filter={true} filterMatchMode='contains' />
                                <Column field="telPers" header="Telephone" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                <Column field="fonctionPers" header="Fonction" style={{ width: '12%' }} filter={true} filterMatchMode='contains' />
                                <Column field="statutPers" header="Statut" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                <Column field="idBoutiq.nomBoutiq" header="Etablissement"  filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}