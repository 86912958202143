import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';


export class FormUsers extends Component {
    constructor() {
        super();
        this.state = {
            matrPers: null,
            liste_personnel: [],
            liste_user: [],
            selectedUser: null,
            selectedGerer: null,
            profilUser: null,
            loginUser: '',
            liste_gerer_boutique:[],
            liste_boutique: [],
            idBoutiq: null,
            actifUser: { label: 'Oui', value: 'Oui' },
            liste_statut: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' }
            ],
            liste_profil: [
                { label: 'PDG', value: 'PDG' },
                { label: 'Coordinateur', value: 'Coordinateur' },
                { label: 'Administrateur', value: 'Administrateur' },
                { label: 'Gérant', value: 'Gérant' },
            ],
            msg: '',
            modif: false,
            visible: false,
            visible_pwd: false,
            visible_gerer: false,
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),

        };
        this.show_dlg_gerer = this.show_dlg_gerer.bind(this);
        this.edit_user = this.edit_user.bind(this);
        this.conf_delete_user = this.conf_delete_user.bind(this);
        this.show_dlg_pwd = this.show_dlg_pwd.bind(this);
        this.reset_password = this.reset_password.bind(this);
        this.enreg_user = this.enreg_user.bind(this);
        this.xulaservice = new Xulaservice();
    }


    show_dlg_gerer(data) {
        this.xulaservice.getListe_boutique().then(data =>{
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
                this.setState({liste_boutique: liste});
            }
        });
        this.get_liste_gerer(data);
        this.setState({visible_gerer: true});
    }


    componentDidMount() {
        this.get_liste_personnel();
        this.get_liste_user();
        
    }


    get_liste_gerer(rowData) {
        this.xulaservice.get_liste_gerer_boutique(rowData.idUser).then(data => this.setState({ liste_gerer_boutique: data }));
    }

    get_liste_personnel() {
        this.xulaservice.get_liste_personnel().then(data => this.setState({ liste_personnel: data }));
    }

    get_liste_user() {
        this.setState({ showindicator: true });
        this.xulaservice.get_liste_users().then(data => this.setState({ liste_user: data, showindicator: false }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_user();
            this.get_liste_user();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_gerer(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_gerer(this.state.selectedUser);
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_user(data) {
        if (data != null && data != undefined) {
            this.setState({ loginUser: data.loginUser });
            this.setState({ profilUser: { label: data.profilUser, value: data.profilUser } });
            this.setState({ actifUser: { label: data.actifUser, value: data.actifUser } });
            this.setState({ matrPers: { value: data.matrPers.matrPers, label: data.matrPers.nomPers+' '+data.matrPers.telPers, nomBoutiq: data.matrPers.idBoutiq.nomBoutiq } });
            this.setState({ selectedUser: data });
            this.setState({ modif: true });
            window.scrollTo(0, 0);
        }
    }

    annule_user() {
        this.setState({ loginUser: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_user() {
        if (this.state.loginUser === "" || this.state.loginUser === undefined || this.state.profilUser === undefined || this.state.profilUser === null || this.state.matrPers === "" || this.state.matrPers === undefined || this.state.matrPers === null
            || this.state.actifUser === undefined || this.state.actifUser === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif === false) {
                let data = {
                    idUser: '0',
                    loginUser: this.state.loginUser,
                    actifUser: this.state.actifUser.value,
                    profilUser: this.state.profilUser.value,
                    pwdUser: '0000',
                    matrPers: { matrPers: this.state.matrPers.value }
                }
                this.setState({ showindicator: true });
                this.xulaservice.enreg_user(data)
                    .then(data => {
                        if (data != undefined && data != null) {
                            this.resultat(data.code, data.contenu);
                        }
                    });
            } else {

                let data = this.state.selectedUser;
                data.loginUser = this.state.loginUser;
                data.actifUser = this.state.actifUser.value;
                data.profilUser = this.state.profilUser.value;
                this.setState({ showindicator: true });
                this.xulaservice.enreg_user(data)
                    .then(data => {
                        if (data != undefined && data != null) {
                            this.resultat(data.code, data.contenu);
                        }
                    });
            }
        }
    }


    enreg_gerer() {
        if (this.state.idBoutiq == null || this.state.idBoutiq == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner boutique' });
        } else {
            this.setState({ showindicator: true });
            let data={idGerer: '0',idUser: this.state.selectedUser,idBoutiq: {idBoutiq: this.state.idBoutiq.value},actifGerer: 'Oui'}
            this.xulaservice.enreg_gerer_boutique(data).then(data => this.resultat_gerer(data.code, data.contenu));
        }
    }
 


    actionTemplate(rowData) {

        return <div className="p-grid" style={{ width: 200 }}>
            <div className="p-col"><Button onClick={() => this.edit_user(rowData)} className="p-button-info" style={{ width: 25, height: 25, marginLeft: '-5px' }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={() => this.reset_password(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: '-5px' }} icon="pi pi-refresh" /></div>
            <div className="p-col"><Button onClick={() => this.conf_delete_user(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: '-5px' }} icon="pi pi-times" /></div>
            <div className="p-col"><Button onClick={() => this.show_dlg_pwd(rowData)} className="p-button-info" style={{ width: 25, height: 25, marginLeft: '-5px' }} icon="pi pi-search" /></div>
            <div className="p-col"><Button disabled={rowData.profilUser!=='Directeur'} onClick={() => this.show_dlg_gerer(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: '-5px' }} icon="pi pi-sitemap" /></div>

        </div>
    }




    reset_password(rowData) {
        if (rowData === null || rowData === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à modifier' });
        } else {
            rowData.pwdUser = '0000';
            this.setState({ showindicator: true });
            this.xulaservice.enreg_user(rowData).then(data => {
                if (data !== null && data !== undefined) {
                    if (data.code === 200) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Reinitialisation effectuée avec succès' });
                    } else {
                        this.growl.show({ severity: 'error', summary: 'Error', detail: 'Echec reinitialisation' });
                    }
                }
                this.setState({ showindicator: false });
            });
        }
    }

    delete_user() {
        if (this.state.selectedUser == null || this.state.selectedUser == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.xulaservice.delete_user(this.state.selectedUser).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_gerer(rowData) {
        this.setState({showindicator: true});
        this.xulaservice.delete_gerer_boutique(rowData).then(data => this.resultat_gerer(data.code, data.contenu));
    }

    actionTemplate_gerer(rowData, column) {
        return <Button title="Activer" onClick={(e) => this.delete_gerer(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" />
    }

    conf_delete_user(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedUser: data });
            this.setState({ msg: data.loginUser });
            this.setState({ visible: true });
        }
    }

    show_dlg_pwd(data) {
        if (data != null && data != undefined) {
            if (this.state.userconnected.profilUser == 'PDG') {
                this.setState({ visible_pwd: true,msg: data.pwdUser });
            } else {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Vous n'etes pas autoriser à effectuer cette action " });
            }

        }
    }
    personnelItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div style={{ fontWeight: 'bold' }} className="p-col-12 p-md-7">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-5">
                            <label>{option.nomBoutiq}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {

        let lpersonnel = null;
        if (this.state.liste_personnel != null && this.state.liste_personnel != undefined) {
            lpersonnel = this.state.liste_personnel.map(x => {
                return { value: x.matrPers, label: x.nomPers+' '+x.telPers, nomBoutiq: x.idBoutiq.nomBoutiq }
            });
        }

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_user();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                </div>
                </Dialog>

                <Dialog header="Mot de passe" visible={this.state.visible_pwd} style={{ width: '250px' }} modal={true}  onHide={() => this.setState({ visible_pwd: false,msg: '' })}>
                    <div>
                        <label style={{fontWeight: 'bold',fontSize: 20}}>{this.state.msg}</label>
                </div>
                </Dialog>
                <Dialog header="Boutiques rattachées" visible={this.state.visible_gerer} style={{ width: '550px' }} modal={true} onHide={(e) => this.setState({ visible_gerer: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 350 }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="profile">Boutique *</label>
                                    </div> 
                                    <div className="p-col-12 p-md-6">
                                        <Dropdown value={this.state.idBoutiq} options={this.state.liste_boutique} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ idBoutiq: e.value })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <Button label="Ajouter" style={{width: 100}} icon="pi pi-check" onClick={(e) => this.enreg_gerer()} />
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                                <DataTable value={this.state.liste_gerer_boutique} selectionMode="single" scrollable={true} scrollHeight="300px"
                                    onRowClick={e => this.setState({ selectedGerer: e.data })} onRowSelect={e => this.setState({ selectedGerer: e.data })}
                                    responsive={true} selection={this.state.selectedGerer} >
                                    <Column header="#" body={this.actionTemplate_gerer.bind(this)} style={{ width: '15%' }} />
                                    <Column field="idBoutiq.nomBoutiq" header="Nom site" filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="fonctionuser">Personnel *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.matrPers} options={lpersonnel} optionLabel='label' panelStyle={{ width: '550px' }} filter={true} filterBy='label,value' onChange={(e) => this.setState({ matrPers: e.value })} autoWidth={false} itemTemplate={this.personnelItemTemplate} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="nomuser">Login *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="nomuser" value={this.state.loginUser} onChange={(e) => this.setState({ loginUser: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="idBoutiq">Profil *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.profilUser} options={this.state.liste_profil} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ profilUser: e.value })} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="idBoutiq">Actif *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.actifUser} options={this.state.liste_statut} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ actifUser: e.value })} autoWidth={false} />
                                </div>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_user()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_user()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>
                            <DataTable value={this.state.liste_user} selectionMode="single" scrollable={true} scrollHeight="750px"
                                onRowClick={e => this.setState({ selectedUser: e.data })}
                                responsive={true} selection={this.state.selectedUser} >
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '15%' }} />
                                <Column field="loginUser" header="Login" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="matrPers.nomPers" header="Nom"  filter={true} filterMatchMode='contains' />
                                <Column field="profilUser" header="Profil" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="matrPers.telPers" header="Téléphone" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                <Column field="actifUser" header="Actif" style={{ width: '6%' }} filter={true} filterMatchMode='contains' />
                                <Column field="matrPers.idBoutiq.nomBoutiq" header="Etablissement" style={{ width: '20%' }} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}