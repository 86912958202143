import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Menu } from 'primereact/menu';
import { SplitButton } from 'primereact/splitbutton';
import { Fieldset } from 'primereact/fieldset';
import { Panel } from 'primereact/panel';
import { ScrollPanel } from 'primereact/scrollpanel';
import Cleave from 'cleave.js/react';
import Numeral from 'numeral';

export class FormTransfert_stock extends Component {
    constructor() {
        super();
        this.state = {
            idTrs: '0',
            libArticle: '',
            cmtTrs: '',
            dateTrs: new Date(),
            idLtrs: 0,
            qteLtrs: 1,
            puLtrs: 0,
            num_ligne: 0,
            pd1: new Date(),
            pd2: new Date(),
            retatTrs: null,
            idBoutiqDest: null,
            idBoutiqSrce: null,
            idUserDest: null,
            rapprobTrs: null,
            rtypeTrs: null,
            rlivrTrs: null,
            rnum_facture: '',
            ridBoutiq_scre: null,
            ridBoutiq_dest: null,
            qte_stock: '0',
            total_transfert: '0',
            idArticle: null,
            liste_etat: [
                { label: 'Tout etat', value: 'Tous' },
                { label: 'Validé', value: 'Validé' },
                { label: 'En attente', value: 'En attente' }
            ],
            liste_approb: [
                { label: 'Approbation', value: 'Tous' },
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' }
            ],
            liste_trs: [],
            liste_users: [],
            liste_selected_users: [],
            liste_ltrs: [],
            liste_boutique: [],
            lboutique_rech: [],
            lboutique_srce: [],
            lboutique: [],
            liste_article: [],
            liste_ligne: [],
            liste_trace: [],
            lprod: [],
            selectedTrs: null,
            selectedLtrs: null,
            selectedLigne: null,
            msg: '',
            receveurBl: '',
            dateBl: new Date(),
            modif: false,
            disable_cmt: false,
            modif_ltrs: false,
            visible: false,
            visible_ltrs: false,
            visible_new_trs_dlg: false,
            visible_dlg_trs: false,
            visible_dlg_ltrs: false,
            visible_dlg_trace: false,
            showindicator: false,
            disable_new_trs: true,
            disable_add_prod: true,
            disable_prod: true,
            selectedLivraisonLsortie: null,
            visible_dlg_livraison: false,
            title_dlg_livr: 'Livraison',
            modif_bl: false,
            visible_dlg_Bl: false,
            liste_livraison_lsortie: [],
            liste_livraison_sortie: [],

            liste_livraison_lsortie_cli: [],
            liste_livraison_sortie_cli: [],

            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };

        this.edit_trs = this.edit_trs.bind(this);
        this.conf_delete_trs = this.conf_delete_trs.bind(this);

        this.edit_ltrs = this.edit_ltrs.bind(this);
        this.conf_delete_ltrs = this.conf_delete_ltrs.bind(this);

        this.show_new_trs_dlg = this.show_new_trs_dlg.bind(this);
        this.show_ltrs_dlg = this.show_ltrs_dlg.bind(this);

        this.conf_delete_ligne = this.conf_delete_ligne.bind(this);
        this.articleItemTemplate = this.articleItemTemplate.bind(this);
        this.qteLtrsEditor = this.qteLtrsEditor.bind(this);
        this.puLtrsEditor = this.puLtrsEditor.bind(this);
        this.show_trace = this.show_trace.bind(this);
        this.conf_print_bs = this.conf_print_bs.bind(this);
        this.show_dlg_livraison = this.show_dlg_livraison.bind(this);
        this.conf_print_bl = this.conf_print_bl.bind(this);
        this.conf_delete_bl = this.conf_delete_bl.bind(this);
        this.conf_delete_bl_cli = this.conf_delete_bl_cli.bind(this);
        this.show_dlg_Bl = this.show_dlg_Bl.bind(this);

        this.conf_print_facture = this.conf_print_facture.bind(this);

        this.qteLivrLsEditor = this.qteLivrLsEditor.bind(this);

        this.myservice = new Xulaservice();
    }


    show_dlg_livraison(data) {
        this.setState({ visible_dlg_livraison: true }, () => {
            this.get_liste_livraison_lsortie_by_sortie(data.idSortieCli);
        });
    }

    get_liste_livraison_lsortie_by_sortie(idSortie) {
        this.myservice.get_liste_livraison_lsortie_by_sortie(idSortie).then(data => {
            this.setState({ liste_livraison_lsortie: data });
        });
    }

    conf_print_facture(idSortie) {
        this.myservice.generer_edition_facture(idSortie, 'Facture', 'A4');
    }


    conf_print_bl(data) {
        this.myservice.generer_edition_bl(data.numBl);
    }

    onEditorValueChange_livraison(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_livraison_lsortie: updateds });
    }

    inputTextEditor_livraison(props, field) {
        return <InputText keyfilter="pint" type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange_livraison(props, e.target.value)} />;
    }

    qteLivrLsEditor(props) {
        return this.inputTextEditor_livraison(props, 'qteLivrLs');
    }

    resultat_livraison(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_livraison();
            this.setState({ liste_livraison_lsortie: [] }, () => {
                this.get_liste_livraison_lsortie_by_sortie(this.state.selectedTrs.idSortieCli);
                this.get_liste_trs();
            });

        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
        this.forceUpdate();
    }

    annule_livraison() {
        this.setState({ modif_bl: false }, () => {
            this.setState({ dateBl: '' });
            this.setState({ receveurBl: '' });
        });
        this.setState({ liste_livraison_lsortie: [] });
    }

    show_dlg_Bl(rowData) {
        this.myservice.get_liste_livraison_sortie_by_sortie(rowData.idSortieRef).then(data => this.setState({ liste_livraison_sortie: data }));
        this.myservice.get_liste_livraison_sortie_by_sortie(rowData.idSortieCli).then(data => this.setState({ liste_livraison_sortie_cli: data }));
        this.setState({ visible_dlg_Bl: true });
    }

    actionTemplate_bl(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.edit_bl(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_bl(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_bl_cli(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.conf_delete_bl_cli(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-times" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_print_bl(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-print" /></div>
        </div>
    }

    get_liste_livraison_lsortie_by_bl(numBl) {
        this.myservice.get_liste_livraison_lsortie_by_bl(numBl).then(data => {
            this.setState({ liste_livraison_lsortie: data });
        });
    }

    get_liste_livraison_lsortie_by_bl_cli(numBl) {
        this.myservice.get_liste_livraison_lsortie_by_bl(numBl).then(data => {
            this.setState({ liste_livraison_lsortie_cli: data });
        });
    }

    conf_delete_bl(rowData) {
        let data = { numBl: rowData.numBl };
        this.myservice.delete_livraison_sortie(data).then(data => {
            this.setState({ liste_livraison_lsortie: [] });
            this.resultat_livraison(data.code, data.contenu);
            this.myservice.get_liste_livraison_sortie_by_sortie(this.state.selectedTrs.idSortieRef).then(data => this.setState({ liste_livraison_sortie: data }));
        });
    }

    conf_delete_bl_cli(rowData) {
        let data = { numBl: rowData.numBl };
        this.myservice.delete_livraison_sortie(data).then(data => {
            this.setState({ liste_livraison_lsortie_cli: [] });
            this.resultat_livraison(data.code, data.contenu);
            this.myservice.get_liste_livraison_sortie_by_sortie(this.state.selectedTrs.idSortieCli).then(data => this.setState({ liste_livraison_sortie_cli: data }));
        });
    }

    enreg_new_bl(idSortieRef) {
        let date_bl = moment(this.state.dateBl, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let data = { numBl: '0', dateBl: date_bl, receveurBl: this.state.receveurBl, idSortie: { idSortie: this.state.selectedTrs.idSortieRef } };
        let data_intermag = { numBl: '0', dateBl: date_bl, receveurBl: '', idSortie: { idSortie: this.state.selectedTrs.idSortieCli } };
        if (this.state.liste_livraison_lsortie == null || this.state.liste_livraison_lsortie == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les articles à livrer' });
            return;
        } else if (this.state.liste_livraison_lsortie.length <= 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les articles à livrer' });
            return;
        }
        for (var i = this.state.liste_livraison_lsortie.length; i--;) {
            this.state.liste_livraison_lsortie[i].numBlLs = data;
            this.state.liste_livraison_lsortie[i].iduser = this.state.userconnected.idUser;
            if (this.state.liste_livraison_lsortie[i].qteLivrLs > this.state.liste_livraison_lsortie[i].qteLivrLs_restant) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier les quantités à livrer' });
                return;
            }
        }
       
        this.setState({ showindicator: true });

        this.myservice.enreg_livraison_lsortie_batch(this.state.liste_livraison_lsortie).then(reponse => {
            if (reponse.code == 200) {
                this.myservice.get_liste_livraison_lsortie_by_sortie(idSortieRef).then(data => {
                    let liste = data;
                    if (liste !== null && liste !== undefined) {
                        let larticle = this.state.liste_livraison_lsortie.map(x => {
                            return x.idLsortie.idArticle.idArticle
                        }).join(";");

                        for (var i = this.state.liste_livraison_lsortie.length; i--;) {
                            for (var j = liste.length; j--;) {
                                if (this.state.liste_livraison_lsortie[i].idLsortie.idArticle.idArticle === liste[j].idLsortie.idArticle.idArticle) {
                                    liste[j].numBlLs = data_intermag;
                                    liste[j].iduser = this.state.userconnected.idUser;
                                    if (liste[j].qteLivrLs_restant < liste[j].qteLivrLs) {
                                        liste[j].qteLivrLs = liste[j].qteLivrLs_restan;
                                    } else {
                                        liste[j].qteLivrLs = this.state.liste_livraison_lsortie[i].qteLivrLs;
                                    }
                                }
                                if (liste[j].qteLivrLs == 0 || liste[j].qteLivrLs_restant <= 0 || larticle.includes(liste[j].idLsortie.idArticle.idArticle) == false) {
                                    liste.splice(j, 1);
                                }
                            }
                        }
                    }
                    if (liste.length > 0) {
                        this.myservice.enreg_livraison_lsortie_batch(liste).then(reponse2 => {
                            if (reponse2 != null && reponse2 != undefined) {
                                this.resultat_livraison(reponse2.code, reponse2.contenu);
                            }
                        });
                    }
                });
            }
        });



    }



    enreg_bl() {
        if (this.state.selectedTrs === null || this.state.selectedTrs === undefined || this.state.dateBl === '' || this.state.dateBl === undefined || this.state.dateBl === null || this.state.receveurBl === undefined || this.state.receveurBl === null || this.state.receveurBl === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner un transfert' });
        } else {
            if (this.state.modif_bl === false) {
                if (this.state.selectedTrs.approbTrs == 'Non') {
                    let dataTrs = this.state.selectedTrs;
                    dataTrs.etatTrs = 'Validé';
                    dataTrs.approbTrs = 'Oui';
                    this.setState({ showindicator: true });
                    this.myservice.enreg_trs(dataTrs).then(rep => {
                        if (rep.code === 200) {
                            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Approbation réussie' });
                            this.enreg_new_bl(rep.id);
                        } else {
                            this.growl.show({ severity: 'error', summary: 'Error', detail: 'Echec approbation' });
                        }
                        this.setState({ showindicator: false });
                    });
                } else {
                    this.enreg_new_bl(this.state.selectedTrs.idSortieRef);
                }
            } else {
                let data = this.state.selectedLivraisonSortie;
                let date_bl = moment(this.state.dateBl, 'DD/MM/YYYY').format('DD/MM/YYYY');
                data.dateBl = date_bl;
                data.receveurBl = this.state.receveurBl;
                this.myservice.enreg_livraison_sortie(data).then(reponse => {
                    if (reponse != null && reponse != undefined) {
                        this.resultat_livraison(reponse.code, reponse.contenu);
                    }
                });
            }
        }
    }

    edit_bl(data) {
        if (data != null && data != undefined) {
            this.setState({ modif_bl: true }, () => {
                this.setState({ dateBl: data.dateBl });
                this.setState({ receveurBl: data.receveurBl });
            });
        }
    }

    articleLivraisonBodyTemplate(rowData, column) {
        let color = 'black';
        if (rowData.qteLivrLs > rowData.qteLivrLs_restant) {
            color = 'red';
        }
        return <div>
            <span style={{ color: color }}>{rowData.idLsortie.idArticle.libArticle}</span><br />
        </div>
    }

    select_users(idBoutiq) {
        let liste = [];
        if (this.state.liste_users != null && this.state.liste_users != undefined) {
            if (this.state.liste_users.length > 0) {
                let liste_filtre = this.state.liste_users.filter(function (el) {
                    return el.matrPers.idBoutiq.idBoutiq === idBoutiq;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.idUser, label: x.loginUser, nomUser: x.matrPers.nomPers, profilUser: x.profilUser }
                        });
                    }
                }
            }
        }
        return liste;
    }


    calculer_total(liste) {
        let tt = 0;
        if (liste != null) {
            for (var i = liste.length; i--;) {
                tt += liste[i].montantLtrs;
            }
        }
        this.setState({ total_transfert: this.formaterMontant(tt) });
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    articleItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-7">
                            <label>{option.label}</label>
                        </div>

                        {this.state.userconnected.profilUser === 'PDG' ?
                            <div style={{ fontWeight: 'bold' }} className="p-col-12 p-md-2">
                                <label>{option.prix_ref_achat_format}</label>
                            </div>
                            : null}

                        <div className="p-col-12 p-md-2">
                            <label>{option.prix_ref_vente_format}</label>
                        </div>
                        <div className="p-col-12 p-md-1" >
                            <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{option.qte_stock_format}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    userItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-7">
                            <label>{option.nomUser}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-3" >
                            <label >{option.profilUser}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }


    puLtrsEditor(props) {
        return this.inputTextEditor(props, 'puLtrs');
    }

    qteLtrsEditor(props) {
        return this.inputTextEditor(props, 'qteLtrs');
    }


    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_ligne: updateds });
    }

    inputTextEditor(props, field) {
        return <InputText keyfilter="pint" type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }
    componentDidMount() {
        this.get_liste_trs();
        this.get_liste_boutique();
        this.get_liste_uers();
    }

    get_liste_uers() {
        this.myservice.get_liste_users().then(data => this.setState({ liste_users: data }));
    }

    get_liste_trs() {
        let rid_boutique_srce = 'Tous';
        let rid_boutique_dest = 'Tous';
        let retat_trs = 'Tous';
        let rtype_trs = 'Tous';
        let rapprob_trs = 'Tous';
        let rlivr = 'Tous';
        let rnum_facture = 'Tous';
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq_scre !== undefined && this.state.ridBoutiq_scre !== null) {
            rid_boutique_srce = this.state.ridBoutiq_scre.value;
        }
        if (this.state.ridBoutiq_dest !== undefined && this.state.ridBoutiq_dest !== null) {
            rid_boutique_dest = this.state.ridBoutiq_dest.value;
        }

        if (this.state.rlivrTrs !== undefined && this.state.rlivrTrs !== null) {
            rlivr = this.state.rlivrTrs.value;
        }
        if (this.state.retatTrs !== undefined && this.state.retatTrs !== null) {
            retat_trs = this.state.retatTrs.value;
        }
        if (this.state.rapprobTrs !== undefined && this.state.rapprobTrs !== null) {
            rapprob_trs = this.state.rapprobTrs.value;
        }

        if (this.state.rtypeTrs !== undefined && this.state.rtypeTrs !== null) {
            rtype_trs = this.state.rtypeTrs.value;
        }
        if (this.state.rnum_facture !== undefined && this.state.rnum_facture !== null) {
            rnum_facture = this.state.rnum_facture;
        }
        this.setState({ showindicator: true });
        this.myservice.get_liste_trs(spd1, spd2, rid_boutique_srce, rid_boutique_dest, retat_trs, rapprob_trs, rtype_trs, rlivr,rnum_facture).then(data => this.setState({ liste_trs: data, showindicator: false }));
    }

    get_liste_ltrs(idTrs) {
        this.setState({ showindicator: true });
        this.myservice.get_liste_ltrs(idTrs).then(data => this.setState({ liste_ltrs: data, showindicator: false }));
    }


    get_liste_boutique() {
        let lboutique = [];
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        this.myservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
                lboutique_rech = lboutique_rech.concat(lboutique);
                this.setState({ lboutique_rech: lboutique_rech })
                this.setState({ lboutique: lboutique });
            }
            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique_srce: lboutique });
            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique_srce: liste });
                }
            }
        }));
    }

    get_liste_article(idBoutiq) {
        this.setState({ showindicator: true });
        this.myservice.get_inventaire_mins('Tous', idBoutiq).then(data => this.setState({ liste_article: data, showindicator: false }, () => {
            let lprod = null;
            if (this.state.liste_article != null && this.state.liste_article != undefined) {
                lprod = this.state.liste_article.map(x => {
                    return { value: x.idArt, label: x.libArt, cbArt: x.cbArt, qte_stock_format: this.formaterMontant(x.qte_stock_reel), prix_ref_vente_format: this.formaterMontant(x.prix_ref_vente), prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock_reel, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: this.formaterMontant(x.prix_ref_achat), label_rech: x.libArt + ' ' + x.cbArt }
                });
                this.setState({ lprod: lprod });
            }
        }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_trs();
            this.setState({ liste_ltrs: [] });
            this.get_liste_trs();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_ltrs(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_ltrs();
            this.setState({ total_transfert: '0' });
            if (this.state.selectedTrs != null && this.state.selectedTrs != undefined) {
                this.get_liste_ltrs(this.state.selectedTrs.idTrs);
            }
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    show_trace(rowData) {
        this.setState({ visible_dlg_trace: true });
        this.myservice.get_liste_trace_by_ref(rowData.idTrs, 'Transfert stock').then(data => this.setState({ liste_trace: data }));
    }

    conf_print_bs(data) {
        this.myservice.generer_edition_bs(data.idTrs);
    }

    edit_trs(data) {
        if (data != null && data != undefined) {
            if (data.etatTrs == 'En attente') {
                this.setState({ idTrs: data.idTrs });
                this.setState({ dateTrs: data.dateTrs });
                this.setState({ idBoutiqSrce: { value: data.idBoutiqSrce.idBoutiq, label: data.idBoutiqSrce.nomBoutiq } });
                this.setState({ idBoutiqDest: { value: data.idBoutiqDest.idBoutiq, label: data.idBoutiqDest.nomBoutiq } });
                this.setState({ cmtTrs: data.cmtTrs });
                let liste = [];
                if (this.state.liste_users != null && this.state.liste_users != undefined) {
                    if (this.state.liste_users.length > 0) {
                        let liste_filtre = this.state.liste_users.filter(function (el) {
                            return el.matrPers.idBoutiq.idBoutiq === data.idBoutiqDest.idBoutiq;
                        });
                        if (liste_filtre != null && liste_filtre != undefined) {
                            if (liste_filtre.length > 0) {
                                liste = liste_filtre.map(x => {
                                    return { value: x.idUser, label: x.loginUser, nomUser: x.matrPers.nomPers, profilUser: x.profilUser }
                                });
                            }
                        }
                    }
                }
                this.setState({ liste_selected_users: liste }, () => {
                    let user = this.state.liste_selected_users.filter(function (el) {
                        return el.value == data.idUserDest.idUser;
                    })[0];
                    this.setState({ idUserDest: user });
                });

                this.setState({ modif: true });
                this.setState({ visible_dlg_trs: true });
                this.setState({ selectedTrs: data });
                window.scrollTo(0, 0);
                this.forceUpdate();
            } else {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier ce transfert, car déja validé' });
            }
        }
    }

    edit_ltrs(data) {
        if (data != null && data != undefined) {
            this.setState({ modif_ltrs: true }, () => {
                let lprod = null;
                this.setState({ showindicator: true });
                this.myservice.get_inventaire_mins('Tous', 'Tous', 'Tous', 'Tous', this.state.selectedTrs.idBoutiqSrce.idBoutiq).then(rep => {
                    this.setState({ liste_article: rep, showindicator: false }, () => {
                        if (this.state.liste_article != null && this.state.liste_article != undefined) {
                            lprod = this.state.liste_article.map(x => {
                                return { value: x.idArt, label: x.libArt, cbArt: x.cbArt, qte_stock_format: this.formaterMontant(x.qte_stock_reel), prix_ref_vente: this.formaterMontant(x.prix_ref_vente), prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock_reel, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: this.formaterMontant(x.prix_ref_achat), label_rech: x.libArt + ' ' + x.cbArt }
                            });
                        }
                        let art = lprod.filter(function (el) {
                            return el.value == data.idArticle.idArticle;
                        })[0];
                        this.setState({ lprod: lprod }, () => {
                            this.setState({ idArticle: art });
                        });
                        this.setState({ qte_stock: art.qte_stock });
                        this.setState({ idLtrs: data.idLtrs });
                        this.setState({ qteLtrs: data.qteLtrs });
                        this.setState({ puLtrs: data.puLtrs });

                        this.setState({ visible_dlg_ltrs: true });
                        this.setState({ disable_prod: true });
                        this.setState({ selectedLtrs: data });
                        this.setState({ libArticle: data.idArticle.libArticle });
                        if (data.typeTrs === 'Compensation') {
                            this.setState({ disable_cmt: true });
                        } else {
                            this.setState({ disable_cmt: false });
                        }
                        window.scrollTo(0, 0);
                        this.forceUpdate();
                    });
                });
            });
            window.scrollTo(0, 0);
            this.forceUpdate();
        }
    }


    annule_trs() {
        this.setState({ idTrs: '0' });
        this.setState({ dateTrs: '' });
        this.setState({ cmtTrs: '' });
        this.setState({ modif: false });
        this.setState({ visible_dlg_trs: false });
        this.forceUpdate();
    }

    annule_trs_dlg() {
        this.annule_trs();
        this.setState({ liste_ligne: [] });
    }

    annule_ltrs() {
        this.setState({ idLtrs: '0' });
        this.setState({ qteLtrs: 0 });
        this.setState({ puLtrs: 0 });
        if (this.state.modif_ltrs === true) {
            this.setState({ visible_dlg_ltrs: false });
        }
        this.setState({ modif_ltrs: false });
        this.setState({ liste_ligne: [] });

        this.forceUpdate();
    }



    enreg_trs() {
        if (this.state.idBoutiqSrce === null || this.state.idBoutiqSrce === undefined || this.state.idBoutiqDest === undefined || this.state.idBoutiqDest === null || this.state.dateTrs === undefined || this.state.dateTrs === null || this.state.dateTrs === '' || this.state.idUserDest === null || this.state.idUserDest === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                if (this.state.liste_ligne == null || this.state.liste_ligne == undefined) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les articles' });
                    return;
                } else if (this.state.liste_ligne.length <= 0) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les articles' });
                    return;
                } else if (this.state.idBoutiqSrce.value == this.state.idBoutiqDest.value) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'La source ne peut etre identique à la destination' });
                    return;
                }
                let date = moment(this.state.dateTrs, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = { idTrs: '0', dateTrs: date, etatTrs: 'En attente', typeTrs: 'Normal', approbTrs: 'Non', cmtTrs: this.state.cmtTrs, idBoutiqSrce: { idBoutiq: this.state.idBoutiqSrce.value }, idBoutiqDest: { idBoutiq: this.state.idBoutiqDest.value }, idUserSrce: { idUser: this.state.userconnected.idUser }, idUserDest: { idUser: this.state.idUserDest.value } };

                for (var i = this.state.liste_ligne.length; i--;) {
                    this.state.liste_ligne[i].idTrs = data;
                    this.state.liste_ligne[i].iduser = this.state.userconnected.idUser;
                }
                this.setState({ showindicator: true });
                this.myservice.enreg_ltrs_batch(this.state.liste_ligne).then(data => {
                    if (data != null && data != undefined) {
                        this.resultat_ltrs(data.code, data.contenu);
                    }
                });

            } else {
                let data = this.state.selectedTrs;
                let date = moment(this.state.dateTrs, 'DD/MM/YYYY').format('DD/MM/YYYY');
                data.cmtTrs = this.state.cmtTrs;
                data.dateTrs = date;
                data.idBoutiqDest = { idBoutiq: this.state.idBoutiqDest.value };
                data.idUserDest = { idUser: this.state.idUserDest.value };
                this.setState({ showindicator: true });
                this.myservice.enreg_trs(data).then(data => this.resultat(data.code, data.contenu));
            }

        }
    }

    update_etat_trs(data, op) {
        if (op == 'Validation') {
            data.etatTrs = 'Validé';
        } else if (op == 'Approbation') {
            data.etatTrs = 'Validé';
            data.approbTrs = 'Oui';
        }
        this.myservice.enreg_trs(data).then(rep => {
            if (rep.code === 200) {
                this.growl.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
                this.setState({ liste_ltrs: [] });
                this.get_liste_trs();
              
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
            }
            this.setState({ showindicator: false });


        });
    }

    enreg_ltrs() {
        if (this.state.selectedTrs == null || this.state.selectedTrs == undefined || this.state.puLtrs == '' || this.state.puLtrs == undefined || this.state.qteLtrs == '' || this.state.qteLtrs == undefined || this.state.idArticle == null || this.state.idArticle == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner un transfert' });
        } else {
            if (this.state.modif_ltrs === false) {
                if (parseFloat(this.state.qteLtrs) > parseFloat(this.state.idArticle.qte_stock)) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: "La quantité saisie n'est pas disponible dans cette boutique" });
                    return;
                }
                let data = { idLtrs: '0', qteLtrs: this.state.qteLtrs, puLtrs: this.state.puLtrs, idTrs: { idTrs: this.state.selectedTrs.idTrs }, idArticle: { idArticle: this.state.idArticle.value } };
                for (var i = this.state.liste_ltrs.length; i--;) {
                    if (this.state.idArticle.value == this.state.liste_ltrs[i].idArticle.idArticle) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Ce article existe déja dans le bon sélectionné, vous ne pouvez que modifier sa quantité' });
                        return;
                    }
                }
                this.setState({ showindicator: true });
                this.myservice.enreg_ltrs(data).then(data => this.resultat_ltrs(data.code, data.contenu));
            } else {
                let qte = parseFloat(this.state.qteLtrs) - parseFloat(this.state.selectedLtrs.qteLtrs);
                if (qte > parseFloat(this.state.idArticle.qte_stock)) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: "La quantité saisie n'est pas disponible dans cette boutique" });
                    return;
                }
                let data = this.state.selectedLtrs;
                data.puLtrs = this.state.puLtrs;
                data.qteLtrs = this.state.qteLtrs;
                this.setState({ showindicator: true });
                this.myservice.enreg_ltrs(data).then(data => this.resultat_ltrs(data.code, data.contenu));
            }
        }
    }



    conf_delete_ligne(data) {
        for (var i = this.state.liste_ligne.length; i--;) {
            if (data.idArticle.idArticle == this.state.liste_ligne[i].idArticle.idArticle) {
                this.state.liste_ligne.splice(i, 1);
                break;
            }
        }
        this.calculer_total(this.state.liste_ligne);
        this.forceUpdate();
    }

    add_article() {
        if (this.state.idBoutiqSrce === null || this.state.idBoutiqSrce === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir une boutique' });
            return;
        }
        Array.prototype.insert = function (index) {
            this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
        };
        let trouve = false;

        if (this.state.idArticle == null || this.state.idArticle == '' || this.state.idArticle == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir un article' });
        } else if (this.state.qteLtrs == null || this.state.qteLtrs == '' || this.state.qteLtrs == 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la quantité ' });
        } else if (this.state.puLtrs === null || this.state.puLtrs === '' || this.state.puLtrs === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir le prix ' });
        } else if (parseFloat(this.state.qteLtrs) > parseFloat(this.state.idArticle.qte_stock)) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier la quantité car supérieure au stock ' });
        }
        else {
            this.setState({ num_ligne: this.state.num_ligne + 1 });
            if (this.state.liste_ligne.length == 0) {
                this.state.liste_ligne.insert(this.state.num_ligne, { idLtrs: '0', qteLtrs: this.state.qteLtrs, puLtrs: this.state.puLtrs, montantLtrs: parseFloat(this.state.qteLtrs) * parseFloat(this.state.puLtrs), idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label } });
            } else {
                for (var i = this.state.liste_ligne.length; i--;) {
                    if (this.state.idArticle.value == this.state.liste_ligne[i].idArticle.idArticle) {
                        trouve = true;
                        this.state.liste_ligne[i].qteLtrs = parseFloat(this.state.liste_ligne[i].qteLtrs) + parseFloat(this.state.qteLtrs);
                    }
                }
                if (trouve == false) {
                    this.state.liste_ligne.insert(this.state.num_ligne, { idLtrs: '0', qteLtrs: this.state.qteLtrs, puLtrs: this.state.puLtrs, montantLtrs: parseFloat(this.state.qteLtrs) * parseFloat(this.state.puLtrs), idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label } });
                }
            }
            this.forceUpdate();
        }
        this.setState({ disable_val_trs: false });
        this.calculer_total(this.state.liste_ligne);
    }


    actionTemplate(rowData, column) {
        let edit = false;
        let items = [];

        if (rowData.etatTrs === 'Validé') {
            if ((rowData.dateEtatTrs === moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY') || rowData.dateApprobTrs === moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY')) && (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur')) {
                items.push({
                    label: 'Supprimer',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.conf_delete_trs(rowData);
                    }
                });
            }
            if (rowData.approbTrs === 'Non' && (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur')) {
                items.push({
                    label: 'Approuver',
                    icon: 'fa fa-check-square',
                    command: (e) => {
                        this.show_dlg_livraison(rowData);
                    }
                });
            }
            if (rowData.approbTrs === 'Oui' && (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur')) {
                items.push({
                    label: 'Livraison',
                    icon: 'pi pi-external-link',
                    command: (e) => {
                        this.show_dlg_livraison(rowData);
                    }
                });

            }
            if (this.state.userconnected.matrPers.idBoutiq.idBoutiq === rowData.idBoutiqSrce.idBoutiq) {
                if (rowData.approbTrs === 'Oui') {
                    items.push({
                        label: 'Livraison',
                        icon: 'pi pi-external-link',
                        command: (e) => {
                            this.show_dlg_livraison(rowData);
                        }
                    });
                }
            }

        } else if (rowData.etatTrs == 'En attente' && (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur')) {
            items.push({
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_trs(rowData);
                }
            });
            items.push({
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_trs(rowData);
                }
            });
            edit = true;

            items.push({
                label: 'Approuver',
                icon: 'fa fa-check-square',
                command: (e) => {
                    this.show_dlg_livraison(rowData);
                }
            });
        } else if (this.state.userconnected.matrPers.idBoutiq.idBoutiq === rowData.idBoutiqSrce.idBoutiq) {
            if (rowData.etatTrs == 'En attente') {
                items.push({
                    label: 'Modifier',
                    icon: 'pi pi-pencil',
                    command: (e) => {
                        this.edit_trs(rowData);
                    }
                });
                edit = true;
                items.push({
                    label: 'Supprimer',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.conf_delete_trs(rowData);
                    }
                });

                if (rowData.typeTrs === 'Compensation') {
                    items.push({
                        label: 'Approuver',
                        icon: 'fa fa-check-square',
                        command: (e) => {
                            this.show_dlg_livraison(rowData);
                        }
                    });
                }
            } else {
                if (rowData.approbTrs === 'Oui') {
                    items.push({
                        label: 'Livraison',
                        icon: 'pi pi-external-link',
                        command: (e) => {
                            this.show_dlg_livraison(rowData);
                        }
                    });
                }
            }
        } else if (rowData.etatTrs == 'En attente' && this.state.userconnected.matrPers.idBoutiq.idBoutiq === rowData.idBoutiqDest.idBoutiq) {
            if (rowData.typeTrs != 'Compensation') {
                items.push({
                    label: 'Valider',
                    icon: 'pi pi-check',
                    command: (e) => {
                        this.update_etat_trs(rowData, 'Validation');
                    }
                });
            }
        }

        items.push({
            label: 'Bon de sortie',
            icon: 'pi pi-print',
            command: (e) => {
                this.conf_print_bs(rowData);
            }
        },
            {
                label: 'BL',
                icon: 'pi pi-file',
                command: (e) => {
                    this.show_dlg_Bl(rowData);
                }
            },
            {
                label: 'Historique',
                icon: 'pi pi-search',
                command: (e) => {
                    this.show_trace(rowData);
                }
            });

        if (rowData.typeTrs == 'Compensation') {
            items.push({
                label: 'Facture',
                icon: 'pi pi-print',
                command: (e) => {
                    this.conf_print_facture(rowData.idSortieCli);
                }
            });
        }

        let label = rowData.ordre + '';

        return <div>
            <SplitButton label={label} onClick={(e) => {
                if (edit === true) {
                    this.edit_trs(rowData);
                } else {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez pas modifier ce transfert' });
                }
            }} icon="pi pi-pencil" model={items}></SplitButton>
        </div>

    }

    actionTemplate_ltrs(rowData, column) {
        let disable_button = true;
        if (this.state.selectedTrs.etatTrs === 'En attente') {
            if (this.state.selectedTrs.typeTrs === 'Normal') {
                disable_button = false;
            }
        }
        return <div className="p-grid" >
            <div className="p-col"><Button disabled={disable_button} onClick={(e) => this.edit_ltrs(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disable_button} onClick={(e) => this.conf_delete_ltrs(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -5 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_ligne(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.conf_delete_ligne(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_trs() {
        if (this.state.selectedTrs == null || this.state.selectedTrs == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_trs(this.state.selectedTrs).then(data => this.resultat(data.code, data.contenu));
        }
    }
    delete_ltrs() {
        if (this.state.selectedLtrs == null || this.state.selectedLtrs == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_ltrs(this.state.selectedLtrs).then(data => this.resultat_ltrs(data.code, data.contenu));
        }
    }


    conf_delete_trs(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedTrs: data });
            this.setState({ msg: 'la ligne sélectionnée ' });
            this.setState({ visible: true });
        }
    }

    conf_delete_ltrs(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedLtrs: data });
            this.setState({ msg: data.idArticle.libArticle });
            this.setState({ visible_ltrs: true });
        }
    }


    show_new_trs_dlg() {
        this.setState({ visible_new_trs_dlg: true });
        this.setState({ modif: false });
    }

    show_ltrs_dlg() {
        this.setState({ visible_dlg_ltrs: true });
        this.setState({ modif_ltrs: false });
        this.setState({ disable_prod: false });
        this.setState({ showindicator: true });
        this.myservice.get_inventaire_mins('Tous', 'Tous', 'Tous', 'Tous', this.state.selectedTrs.idBoutiqSrce.idBoutiq).then(rep => {
            this.setState({ liste_article: rep, showindicator: false }, () => {
                let lprod = null;
                if (this.state.liste_article != null && this.state.liste_article != undefined) {
                    lprod = this.state.liste_article.map(x => {
                        return { value: x.idArt, label: x.libArt, cbArt: x.cbArt, qte_stock_format: this.formaterMontant(x.qte_stock_reel), prix_ref_vente_format: this.formaterMontant(x.prix_ref_vente), prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock_reel, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: this.formaterMontant(x.prix_ref_achat), label_rech: x.libArt + ' ' + x.cbArt }
                    });
                }
                this.setState({ lprod: lprod });
            });
        });

    }

    form_livraison() {
        return <div className="p-grid">
            <div className="p-col-12">
                <div className="card card-w-title" style={{ height: 400 }}>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-2">
                            <label htmlFor="libCodif">Date *</label>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <Calendar value={this.state.dateBl} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateBl: e.value })} />
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label htmlFor="descParamCodif">Receveur *</label>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <InputText value={this.state.receveurBl} onChange={(e) => this.setState({ receveurBl: e.target.value })} />
                        </div>
                    </div>
                    <DataTable value={this.state.liste_livraison_lsortie} selectionMode="single" scrollable={true} scrollHeight="250px"
                        onRowClick={e => this.setState({ selectedLivraisonLsortie: e.data })} onRowSelect={e => this.setState({ selectedLivraisonLsortie: e.data })}
                        responsive={true} selection={this.state.selectedLivraisonLsortie} style={{ marginBottom: 12 }} editable={true}>
                        <Column field="idLsortie.idArticle.libArticle" body={this.articleLivraisonBodyTemplate} header="Désignation" style={{ width: '55%' }} />
                        <Column field="idLsortie.qteLsortie" header="Achetée" style={{ width: '15%' }} />
                        <Column field="qteLivrLs_restant" header="Restant" style={{ width: '15%' }} />
                        <Column field="qteLivrLs" header="Livrer" style={{ width: '15%' }} editor={this.qteLivrLsEditor} />
                    </DataTable>
                    <center>
                        <div className="p-grid" style={{ width: 300 }}>
                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_livraison()} /></div>
                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_bl()} /></div>
                        </div>
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                </div>
            </div>
        </div>
    }
    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_trs();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_larv = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_ltrs: false });
                    this.delete_ltrs();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_ltrs: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_trace = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_dlg_trace: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const header_trs = <div style={{ textAlign: 'left' }}>
            <Button type="button" icon="pi pi-plus" iconPos="left" label="Nouveau transfert" style={{ width: 200 }} onClick={this.show_new_trs_dlg}></Button>
        </div>;

        const header_larv = <div style={{ textAlign: 'left' }}>
            <Button type="button" disabled={this.state.disable_add_prod} icon="pi pi-plus" iconPos="left" label="Ajouter un produit" style={{ width: 200 }} onClick={this.show_ltrs_dlg}></Button>
            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
        </div>;

        const footer_ltrs = <label style={{ color: 'red', fontSize: '22px', fontWeight: 'bold' }} htmlFor="in">TOTAL = {this.state.total_transfert}</label>

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />

                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible_ltrs} style={{ width: '250px' }} modal={true} footer={dialogFooter_larv} onHide={(e) => this.setState({ visible_ltrs: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>


                <Dialog header="Bons de livraison" visible={this.state.visible_dlg_Bl} style={{ width: '80%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_Bl: false })} >
                    <i className="fa fa-fw fa-search"></i>BON DE LIVRAISON INTERNE
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card card-w-title" style={{ height: 200 }}>
                                        {this.state.modif_bl == true ?
                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="libCodif">Date *</label>
                                                </div>
                                                <div className="p-col-12 p-md-3">
                                                    <Calendar value={this.state.dateBl} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateBl: e.value })} />
                                                </div>
                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="descParamCodif">Receveur *</label>
                                                </div>
                                                <div className="p-col-12 p-md-5">
                                                    <InputText value={this.state.receveurBl} onChange={(e) => this.setState({ receveurBl: e.target.value })} />
                                                </div>
                                            </div>
                                            : null}
                                        {this.state.modif_bl == true ?
                                            <center>
                                                <div className="p-grid" style={{ width: 300 }}>
                                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_livraison()} /></div>
                                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_bl()} /></div>
                                                </div>
                                            </center>
                                            : null}

                                        <DataTable value={this.state.liste_livraison_sortie} scrollable={true} scrollHeight="320px"
                                            selectionMode="single" selection={this.state.selectedLivraisonSortie}
                                            onRowClick={e => this.setState({ selectedLivraisonSortie: e.data })} onRowSelect={e =>
                                                this.setState({ selectedLivraisonSortie: e.data }, () => {
                                                    this.get_liste_livraison_lsortie_by_bl(this.state.selectedLivraisonSortie.numBl);
                                                })
                                            }
                                            responsive={true} >
                                            <Column header="#" body={this.actionTemplate_bl.bind(this)} style={{ width: '16%' }} />
                                            <Column field="numBl" header="N° BL" style={{ width: '30%' }} />
                                            <Column field="dateBl" header="Date" style={{ width: '16%' }} />
                                            <Column field="receveurBl" header="Receveur" style={{ width: '38%' }} />
                                        </DataTable>

                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="p-col-12 p-lg-6">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card card-w-title" style={{ height: 200 }}>
                                        <DataTable value={this.state.liste_livraison_lsortie} scrollable={true} scrollHeight="150px"
                                            responsive={true} >
                                            <Column field="idLsortie.idArticle.libArticle" body={this.articleLivraisonBodyTemplate} header="Désignation" style={{ width: '70%' }} />
                                            <Column field="idLsortie.qteLsortie" header="Achétée" style={{ width: '15%' }} />
                                            <Column field="qteLivrLs" header="Livrée" style={{ width: '15%' }} />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>




                    <i className="fa fa-fw fa-search"></i>BON DE LIVRAISON DU CLIENT
                    <div className="p-grid">

                        <div className="p-col-12 p-lg-6">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card card-w-title" style={{ height: 200 }}>
                                        <DataTable value={this.state.liste_livraison_sortie_cli} scrollable={true} scrollHeight="120px"
                                            selectionMode="single" selection={this.state.selectedLivraisonSortie_cli}
                                            onRowClick={e => this.setState({ selectedLivraisonSortie_cli: e.data })} onRowSelect={e =>
                                                this.setState({ selectedLivraisonSortie_cli: e.data }, () => {
                                                    this.get_liste_livraison_lsortie_by_bl_cli(this.state.selectedLivraisonSortie_cli.numBl);
                                                })
                                            }
                                            responsive={true} >
                                            <Column header="#" body={this.actionTemplate_bl_cli.bind(this)} style={{ width: '16%' }} />
                                            <Column field="numBl" header="N° BL" style={{ width: '30%' }} />
                                            <Column field="dateBl" header="Date" style={{ width: '16%' }} />
                                            <Column field="receveurBl" header="Receveur" style={{ width: '38%' }} />
                                        </DataTable>

                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="p-col-12 p-lg-6">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card card-w-title" style={{ height: 200 }}>
                                        <DataTable value={this.state.liste_livraison_lsortie_cli} scrollable={true} scrollHeight="250px"
                                            responsive={true} >
                                            <Column field="idLsortie.idArticle.libArticle" body={this.articleLivraisonBodyTemplate} header="Désignation" style={{ width: '70%' }} />
                                            <Column field="idLsortie.qteLsortie" header="Achétée" style={{ width: '15%' }} />
                                            <Column field="qteLivrLs" header="Livrée" style={{ width: '15%' }} />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </Dialog>

                <Dialog header={this.state.title_dlg_livr} visible={this.state.visible_dlg_livraison} style={{ width: '70%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_livraison: false })} >
                    {this.form_livraison()}
                </Dialog>


                <Dialog header="Historique" visible={this.state.visible_dlg_trace} style={{ width: '70%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_trace: false })} footer={dialogFooter_trace}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 400 }}>
                                <DataTable value={this.state.liste_trace} scrollable={true} scrollHeight="350px"
                                    responsive={true} style={{ marginBottom: 12 }}>
                                    <Column field="dateTrace" header="Date" style={{ width: '20%' }} />
                                    <Column field="actionTrace" header="Action" style={{ width: '15%' }} />
                                    <Column field="idutil.loginUser" header="Utilisateur" style={{ width: '15%' }} />
                                    <Column field="detailTrace" header="Détail" style={{ width: '50%' }} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Nouveaux transfert" visible={this.state.visible_new_trs_dlg} style={{ width: '80%' }} modal={true} onHide={(e) => this.setState({ visible_new_trs_dlg: false })}>

                    <div className="p-grid">
                        <div className="p-col-12 p-lg-3">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <Panel style={{ minHeight: '500px' }} header="Ajout d'article">
                                        <div className="p-grid">

                                            <div className="p-col-12">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Boutique source *</label>
                                                </span>
                                                <Dropdown options={this.state.lboutique_srce} value={this.state.idBoutiqSrce} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                                    this.setState({ idBoutiqSrce: e.value }, () => {
                                                        this.get_liste_article(this.state.idBoutiqSrce.value);
                                                    })
                                                }
                                                } autoWidth={false} />
                                            </div>

                                            <div className="p-col-12">
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Article *</label>
                                                </span>
                                                <div className="p-inputgroup">
                                                    <Dropdown options={this.state.lprod} placeholder="Articles" value={this.state.idArticle} panelStyle={{ width: '500px' }} optionLabel='label_rech' filter={true} filterBy='label_rech' itemTemplate={this.articleItemTemplate} filterMatchMode='contains'
                                                        onChange={(e) => {
                                                            this.setState({ idArticle: e.value }, () => {
                                                                if (this.state.userconnected.idEtabl.codeEtabl === 'CNTIS') {
                                                                    this.setState({ puLtrs: 0 });
                                                                } else {
                                                                    this.setState({ puLtrs: this.state.idArticle.prix_ref_vente });
                                                                }
                                                                this.setState({ qte_stock: this.state.idArticle.qte_stock_format });
                                                            })
                                                        }
                                                        } autoWidth={false} style={{ width: '90%' }} />
                                                    <span className="p-inputgroup-addon" style={{ backgroundColor: '#f5f8f9', color: 'red', fontWeight: 'bold', width: 70 }}>{this.state.qte_stock}</span>
                                                </div>
                                            </div>

                                            <div className="p-col-12">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Qauntité *</label>
                                                </span>
                                                <InputText value={this.state.qteLtrs} onChange={(e) => this.setState({ qteLtrs: e.target.value })} />
                                            </div>

                                            {this.state.userconnected.idEtabl.codeEtabl !== 'CNTIS' ?
                                                <div className="p-col-12">
                                                    <span >
                                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Prix unitaire *</label>
                                                    </span>
                                                    <Cleave value={this.state.puLtrs} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ puLtrs: e.target.rawValue })} className="p-inputtext" />
                                                </div>
                                                : null}

                                        </div>
                                        <center>
                                            <Button label="Ajouter >>" style={{ width: '100px' }} icon="pi pi-plus" onClick={(e) => this.add_article()} />
                                        </center>
                                    </Panel>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-lg-9">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <Panel style={{ minHeight: '380px' }}>
                                        <div className="p-grid">

                                            <DataTable value={this.state.liste_ligne} selectionMode="single" scrollable={true} scrollHeight="300px"
                                                onRowClick={e => this.setState({ selectedLigne: e.data })} onRowSelect={e => this.setState({ selectedLigne: e.data })}
                                                responsive={true} selection={this.state.selectedLigne} style={{ marginBottom: 12 }}
                                                editable={true} footer={footer_ltrs}>
                                                <Column header="#" body={this.actionTemplate_ligne.bind(this)} style={{ width: '5%' }} />
                                                <Column field="idArticle.libArticle" header="Produit" style={{ width: '50%' }} />
                                                <Column field="qteLtrs" header="Qté" style={{ width: '10%' }} editor={this.qteLtrsEditor} />
                                                <Column field="puLtrs" body={(rowData, column) => {
                                                    return <div>
                                                        <span style={{ color: '#000000' }}>{this.formaterMontant(rowData.puLtrs)}</span>
                                                    </div>
                                                }} header="Prix" style={{ width: '15%' }} editor={this.puLtrsEditor} />
                                                <Column field="montantLtrs" body={(rowData, column) => {
                                                    return <div>
                                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.montantLtrs)}</span>
                                                    </div>
                                                }} header="Montant" style={{ width: '20%' }} editor={this.puLtrsEditor} />

                                            </DataTable>
                                        </div>
                                    </Panel>
                                    <div className="card card-w-title" style={{ marginBottom: '10px' }}>
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-3">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Boutique destination *</label>
                                                </span>
                                                <Dropdown options={this.state.lboutique} value={this.state.idBoutiqDest} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                                    this.setState({ idBoutiqDest: e.value }, () => {
                                                        this.setState({ liste_selected_users: this.select_users(this.state.idBoutiqDest.value) });
                                                    })
                                                }
                                                } autoWidth={false} />
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Receveur * </label>
                                                </span>
                                                <Dropdown options={this.state.liste_selected_users} value={this.state.idUserDest} optionLabel='label' itemTemplate={this.userItemTemplate} filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                                    this.setState({ idUserDest: e.value })
                                                }
                                                } autoWidth={false} panelStyle={{ width: '500px' }} />
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Date *</label>
                                                </span>
                                                <Calendar value={this.state.dateTrs} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateTrs: e.value })} />
                                            </div>

                                            <div className="p-col-12 p-md-3">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Commentaire </label>
                                                </span>
                                                <InputText value={this.state.cmtTrs} onChange={(e) => this.setState({ cmtTrs: e.target.value })} />
                                            </div>
                                        </div>

                                        <center>
                                            <div className="p-grid" style={{ width: 300 }}>
                                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_trs_dlg()} /></div>
                                                <div className="p-col"><Button disabled={this.state.disable_val_trs} label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_trs()} /></div>
                                            </div>
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </center>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Dialog>


                <Dialog header="Modification transfert" visible={this.state.visible_dlg_trs} style={{ width: '500px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_trs: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label >Date  *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Calendar value={this.state.dateTrs} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateTrs: e.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label >Boutique destination *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={this.state.lboutique} value={this.state.idBoutiqDest} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                            this.setState({ idBoutiqDest: e.value }, () => {
                                                this.setState({ liste_selected_users: this.select_users(this.state.idBoutiqDest.value) }, () => {
                                                    if (this.state.liste_selected_users.length > 0) {
                                                        this.setState({ idUserDest: this.state.liste_selected_users[0] });
                                                    }
                                                });

                                            });
                                        }} autoWidth={false} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label >Receveur *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={this.state.liste_selected_users} value={this.state.idUserDest} optionLabel='label' itemTemplate={this.userItemTemplate} filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                            this.setState({ idUserDest: e.value })
                                        }
                                        } autoWidth={false} panelStyle={{ width: '500px' }} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label >Commentaire</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText value={this.state.cmtTrs} disabled={this.state.disable_cmt} onChange={(e) => this.setState({ cmtTrs: e.target.value })} />
                                    </div>

                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_trs()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_trs()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>



                <Dialog header="Modification détail transfert" visible={this.state.visible_dlg_ltrs} style={{ width: '550px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_ltrs: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Article *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">

                                        <div className="p-inputgroup">
                                            <Dropdown options={this.state.lprod} value={this.state.idArticle} panelStyle={{ width: '500px' }} optionLabel='label_rech' filter={true} filterBy='label_rech' itemTemplate={this.articleItemTemplate} filterMatchMode='contains'
                                                onChange={(e) => {
                                                    this.setState({ idArticle: e.value }, () => {
                                                        this.setState({ puLtrs: this.state.idArticle.prix_ref_vente });
                                                        this.setState({ qte_stock: this.state.idArticle.qte_stock });
                                                    })
                                                }
                                                } autoWidth={false} />
                                            <span className="p-inputgroup-addon" style={{ backgroundColor: '#f5f8f9', color: 'red', fontWeight: 'bold', width: 70 }}>{this.state.qte_stock}</span>
                                        </div>

                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Qauntité *</label>
                                    </div>

                                    <div className="p-col-12 p-md-9">
                                        <InputText keyfilter="pint" value={this.state.qteLtrs} onChange={(e) => this.setState({ qteLtrs: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Prix unitaire * </label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Cleave value={this.state.puLtrs} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ puLtrs: e.target.rawValue })} className="p-inputtext" />
                                    </div>
                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => { this.setState({ visible_dlg_ltrs: false }) }} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_ltrs()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>



                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" >
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <Dropdown options={this.state.lboutique_rech} placeholder="Etablissement source" value={this.state.ridBoutiq_scre} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq_scre: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <Dropdown options={this.state.lboutique_rech} placeholder="Etablissement destination" value={this.state.ridBoutiq_dest} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq_dest: e.value })} autoWidth={false} />
                                        </div>

                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={this.state.liste_etat} placeholder="Etat" value={this.state.retatTrs} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ retatTrs: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={this.state.liste_approb} placeholder="Etat" value={this.state.rapprobTrs} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rapprobTrs: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={[{ value: 'Normal', label: 'Normal' }, { value: 'Compensation', label: 'Inter magasin' }]} placeholder="Type transfert" value={this.state.rtypeTrs} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rtypeTrs: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <Dropdown options={[
                                                { label: 'Livraison', value: 'Tous' },
                                                { label: 'Livré', value: 'LIVRE' },
                                                { label: 'Non livré', value: 'NON LIVRE' }
                                            ]} placeholder="Livraison" value={this.state.rlivrTrs} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rlivrTrs: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <InputText value={this.state.rnum_facture} placeholder="N° sortie" onChange={(e) => this.setState({ rnum_facture: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <div className="p-inputgroup">
                                                <Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_trs()} style={{ width: 200 }} />
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-grid">
                                <div className="p-col-12 p-lg-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 600 }}>
                                                <ScrollPanel style={{ width: '100%', height: '590px' }}>
                                                    <DataTable value={this.state.liste_trs} selectionMode="single" paginatorPosition="top" paginator={true} rows={10}
                                                        onRowClick={e => this.setState({ selectedTrs: e.data })} onRowSelect={e =>

                                                            this.setState({ selectedTrs: e.data }, () => {
                                                                this.get_liste_ltrs(this.state.selectedTrs.idTrs);
                                                                if (this.state.selectedTrs.etatTrs == 'En attente' && (this.state.userconnected.profilUser === 'PDG' || this.state.selectedTrs.idUserSrce.idUser === this.state.userconnected.idUser)) {
                                                                    if (this.state.selectedTrs.typeTrs === 'Normal') {
                                                                        this.setState({ disable_add_prod: false });
                                                                    } else {
                                                                        this.setState({ disable_add_prod: true });
                                                                    }
                                                                } else {
                                                                    this.setState({ disable_add_prod: true });
                                                                }
                                                            })
                                                        }
                                                        header={header_trs} style={{ width: '100%' }}
                                                        responsive={true} selection={this.state.selectedTrs} >
                                                        <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '15%', overflow: 'visible' }} />
                                                        <Column field="idTrs" body={(rowData, column) => {
                                                            return <div>
                                                                <span style={{ color: '#000000' }}>{rowData.dateTrs}</span>
                                                            </div>
                                                        }} header="Référence" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="etatTrs" body={(rowData, column) => {
                                                            let appr = 'Non approuvé';
                                                            if (rowData.approbTrs === 'Oui') {
                                                                appr = 'Approuvé';
                                                            }
                                                            return <div>
                                                                <span style={{ color: '#000000' }}>{rowData.etatTrs}</span> <br />
                                                                <span style={{ color: '#000000', fontWeight: 'bold' }}>{appr}</span>
                                                            </div>
                                                        }} header="Etat" style={{ width: '19%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="numFactureSortie" body={(rowData, column) => {
                                                            return <div>
                                                                <span style={{ color: 'green', fontWeight: 'bold' }}>{this.formaterMontant(rowData.mtTrs)}</span> <br />
                                                                <span style={{ color: '#F34C1F', fontWeight: 'bold' }}>{rowData.numFactureSortie}</span><br />
                                                                <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.statutLivrTrs}</span>
                                                            </div>
                                                        }} header="Montant" style={{ width: '26%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="idBoutiqDest.nomBoutiq" body={(rowData, column) => {
                                                            let typetrs = '';
                                                            if (rowData.typeTrs === 'Compensation') {
                                                                typetrs = 'Inter-MAG';
                                                            } else {
                                                                typetrs = 'Transfert';
                                                            }
                                                            return <div>
                                                                <span style={{ color: '#000000', fontWeight: 'bold' }}>{typetrs}</span> <br />
                                                                <div className="p-grid" >
                                                                    <div className="p-col">
                                                                        <span style={{ color: '#000000' }}>{rowData.idBoutiqSrce.codeBoutiq}</span> <br />
                                                                    </div>
                                                                    <div className="p-col">
                                                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.idBoutiqDest.codeBoutiq}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }} header="Etablissement" style={{ width: '28%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    </DataTable>
                                                </ScrollPanel>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 600 }}>
                                                <DataTable value={this.state.liste_ltrs} selectionMode="single" scrollable={true} scrollHeight="460px"
                                                    onRowClick={e => this.setState({ selectedLtrs: e.data })} onRowSelect={e => this.setState({ selectedLtrs: e.data })}
                                                    responsive={true} selection={this.state.selectedLtrs} header={header_larv} >
                                                    <Column header="#" body={this.actionTemplate_ltrs.bind(this)} style={{ width: '15%' }} />
                                                    <Column field="idArticle.libArticle" header="Article" style={{ width: '43%' }} />
                                                    <Column field="qteLtrs" header="Qté" style={{ width: '12%' }} />
                                                    <Column field="puLtrs" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: '#000000' }}>{this.formaterMontant(rowData.puLtrs)}</span>
                                                        </div>
                                                    }} header="Prix" style={{ width: '15%' }} />
                                                    <Column body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.puLtrs * rowData.qteLtrs)}</span>
                                                        </div>
                                                    }} header="Montant" style={{ width: '15%' }} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}